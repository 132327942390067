import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

import blockStyle from 'pages/Proposal/components/Publish/helpers/blockStyle';
import { formatLocale } from 'pages/Proposal/components/RichEditor/wix-components/wix-pricing-helper';
import helpers from 'helpers/proposal';
import utils from 'utils/utils';
import { DEFAULT_TEMPLATE_VARIABLES, DEFAULT_TEMPLATE_PRICING } from 'constants/index';

// Rendering the published header section

const PublishHeaderSection = ({ name, proposal, templateWixPreview, isPreview }) => {
  let { draft } = proposal;
  const section = draft[name];

  const locale = formatLocale(proposal?.wixPricing || DEFAULT_TEMPLATE_PRICING);
  const formatter = new Intl.DateTimeFormat(locale);
  const formattedCreatedDate = formatter.format(new Date());

  let variables = templateWixPreview
    ? {
        ...DEFAULT_TEMPLATE_VARIABLES,
        proposal: {
          ...DEFAULT_TEMPLATE_VARIABLES.proposal,
          createdDate: {
            ...DEFAULT_TEMPLATE_VARIABLES.proposal.createdDate,
            value: formattedCreatedDate,
          },
        },
      }
    : draft.variables;

  const titleDisplay = section?.titleStyle?.titleDisplay
    ? section?.titleStyle?.titleDisplay
    : 'block';
  const titleWidth = section?.titleStyle?.titleWidth ? section?.titleStyle?.titleWidth : '28';

  if (!proposal.draft || !section) {
    return null;
  }

  let titlehtml = section.title;
  if (section.rawtitle) {
    try {
      const titlecs = convertFromRaw(section.rawtitle);
      titlehtml = stateToHTML(titlecs, blockStyle({ language: proposal.language }));
      titlehtml = utils.changeVariable(variables, titlehtml);
    } catch (e) {}
  }

  let subtitlehtml = section.subtitle;
  if (section.rawsubtitle) {
    try {
      const subtitlecs = convertFromRaw(section.rawsubtitle);
      subtitlehtml = stateToHTML(subtitlecs, blockStyle({ language: proposal.language }));
      subtitlehtml = utils.changeVariable(variables, subtitlehtml);
    } catch (e) {}
  }

  let namehtml = section.name;
  if (section.rawname) {
    const namecs = convertFromRaw(section.rawname);
    namehtml = stateToHTML(namecs, blockStyle({ language: proposal.language }));
    namehtml = utils.changeVariable(variables, namehtml);
  }

  let emailhtml = section.email;
  if (section.rawemail) {
    const emailcs = convertFromRaw(section.rawemail);
    emailhtml = stateToHTML(emailcs, blockStyle({ language: proposal.language }));
    emailhtml = utils.changeVariable(variables, emailhtml);
  }

  let contacthtml = section.contact || 'Contact';
  if (section.rawcontact) {
    const contactcs = convertFromRaw(section.rawcontact);
    contacthtml = stateToHTML(contactcs, blockStyle({ language: proposal.language }));
    contacthtml = utils.changeVariable(variables, contacthtml);
  }

  let byhtml = section.by || 'By';
  if (section.rawby) {
    const bycs = convertFromRaw(section.rawby);
    byhtml = stateToHTML(bycs, blockStyle({ language: proposal.language }));
    byhtml = utils.changeVariable(variables, byhtml);
  }

  const draftHeaderConfig = draft[name]?.headerConfig || draft?.titleFont;

  const getHeaderCoverSize =
    draftHeaderConfig && draftHeaderConfig.cover ? draftHeaderConfig.cover : false;

  // const getHeaderCoverTextAlignment =
  //   proposal?.language?.toLowerCase() === 'hebrew'
  //     ? 'right'
  //     : draft.titleFont && draft.titleFont.coverTextAlignment
  //       ? draft.titleFont.coverTextAlignment
  //       : false;

  const getHeaderCoverTextAlignment = draftHeaderConfig?.coverTextAlignment || false;

  const getHeaderLogoPosition =
    draftHeaderConfig && draftHeaderConfig.logoPosition ? draftHeaderConfig.logoPosition : false;

  let sectionAlignment = 'middle';
  let headerAlignmentClass = '';
  let logoPositionClass = 'logoposition-left';

  if (!section.logo) {
    logoPositionClass = 'logoposition-right';
  }

  let hideUserCover = false;
  if (section?.hidecover === true) {
    hideUserCover = section.hidecover;
  }

  const headerStyle = {
    position: 'relative',
    overflow: 'visible',
    display: !isPreview && hideUserCover === true ? 'none' : 'block',
  };

  if (getHeaderCoverSize) {
    if (getHeaderCoverSize === 'top') {
      headerStyle.height = '100%';
    } else if (getHeaderCoverSize === 'full') {
      headerStyle.height = '1250px';
    }
  }

  if (getHeaderCoverTextAlignment) {
    switch (getHeaderCoverTextAlignment) {
      case 'left':
        headerAlignmentClass = 'proposalheader-left';
        break;
      case 'right':
        headerAlignmentClass = 'proposalheader-right';
        break;
      case 'center':
        headerAlignmentClass = 'proposalheader-center';
        break;
      case 'custom':
        headerAlignmentClass = 'proposalheader-custom';
        break;
      default:
        headerAlignmentClass = 'proposalheader-left';
    }
  }

  if (getHeaderLogoPosition) {
    switch (getHeaderLogoPosition) {
      case 'left':
        logoPositionClass = 'logoposition-left';
        break;
      case 'right':
        logoPositionClass = 'logoposition-right';
        break;
      default:
        logoPositionClass = 'logoposition-left';
    }
  }

  const { date } = proposal;

  if (!section.logo && new Date(date) < new Date(2020, 3, 21)) {
    sectionAlignment = 'middle';
  } else if (!section.logo && new Date(date) > new Date(2020, 3, 21)) {
    sectionAlignment = 'full';
  } else if (section.logo && headerAlignmentClass === 'proposalheader-custom') {
    sectionAlignment = 'custom';
  }

  const previewStyles = {
    paddingLeft: 0,
    paddingRight: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    fontSize: '30px',
  };

  const languageRTL =
    section?.language?.toLowerCase() === 'hebrew' ||
    section?.language?.toLowerCase() === 'arabic' ||
    proposal?.language?.toLowerCase() === 'hebrew' ||
    proposal?.language?.toLowerCase() === 'arabic';

  return (
    <div className={`section-wrapper ${languageRTL ? 'language-rtl' : ''}`}>
      <div
        id="proposal-headersection"
        className={`proposal-headersection ${headerAlignmentClass}`}
        style={headerStyle}>
        <div
          className="cover"
          style={{
            backgroundImage: `url("${utils.replaceHttp(section?.cover)}")`,
          }}
        />
        <div className="header-color" style={{ backgroundColor: helpers.torgb(section.color) }} />
        <Row
          className={`header-section-container ${isPreview ? 'padding-left-none' : ''}`}
          style={
            headerAlignmentClass === 'proposalheader-custom'
              ? getHeaderCoverSize === 'top'
                ? { height: '18em', marginBottom: '6em' }
                : { height: '43em' }
              : {}
          }>
          {(sectionAlignment === 'middle' || sectionAlignment === 'custom') && section?.logo && (
            <Col
              xs={24}
              sm={8}
              md={8}
              lg={8}
              className={`logo-section-col ${isPreview ? 'max-width-28p' : ''}`}
              style={{
                display: 'flex',
                alignItems: 'start',
                position: sectionAlignment === 'custom' ? 'absolute' : 'inherit',
                zIndex: 9,
              }}>
              <div className="logo-section">
                <img
                  className={`logo ${isPreview ? 'max-width-140' : ''}`}
                  alt="logo"
                  style={section.logo ? {} : { opacity: 0 }}
                  src={utils.replaceHttp(section.logo)}
                />
              </div>
            </Col>
          )}

          <Col
            className="z-index-9"
            xs={sectionAlignment === 'middle' ? 24 : 24}
            sm={sectionAlignment === 'middle' ? 16 : 24}
            md={sectionAlignment === 'middle' ? 16 : 24}
            lg={sectionAlignment === 'middle' ? 16 : 24}>
            {headerAlignmentClass === 'proposalheader-custom' && (
              <h1
                className={`titles proposalheaderwhite lightgrey`}
                style={!isPreview ? helpers.getStyleObj(proposal, 'title2', sectionAlignment) : {}}
                dangerouslySetInnerHTML={{ __html: subtitlehtml }}
              />
            )}

            <Row style={isPreview && previewStyles}>
              <h1
                className="titles proposalheaderwhite"
                style={isPreview ? previewStyles : helpers.getStyleObj(proposal, 'title1')}
                dangerouslySetInnerHTML={{ __html: titlehtml }}
              />

              <div className="resizable-container">
                <React.Fragment>
                  {titleDisplay === 'block' && (
                    <div
                      className="title-under-line"
                      style={{
                        borderColor: helpers.getStyleObj(proposal, 'title2').color,
                        maxWidth: `${titleWidth}px`,
                      }}
                    />
                  )}
                </React.Fragment>
              </div>
            </Row>

            {headerAlignmentClass !== 'proposalheader-custom' && (
              <h1
                className={`titles proposalheaderwhite lightgrey ${
                  isPreview ? 'font-size-28' : ''
                }`}
                style={helpers.getStyleObj(proposal, 'title2')}
                dangerouslySetInnerHTML={{ __html: subtitlehtml }}
              />
            )}
          </Col>
        </Row>

        <Row
          className={`header-by-container z-index-9 ${
            isPreview ? 'padding-left-none max-width-84p' : ''
          }`}
          style={
            isPreview && languageRTL && !section?.logo
              ? { right: '0' }
              : isPreview && languageRTL
              ? { right: '10.5rem' }
              : {}
          }>
          {(languageRTL && section.logo && headerAlignmentClass !== 'proposalheader-custom') ||
          (languageRTL &&
            section.logo &&
            headerAlignmentClass !== 'proposalheader-custom' &&
            isPreview) ? (
            <Col md={8} sm={8} xs={0} />
          ) : (
            ''
          )}
          <Col
            className={!section.logo && isPreview ? 'margin-left-none' : ''}
            md={
              sectionAlignment === 'middle'
                ? {
                    span: 6,
                    offset: languageRTL || logoPositionClass === 'logoposition-right' ? 0 : 8,
                  }
                : { span: 12 }
            }
            lg={
              sectionAlignment === 'middle'
                ? {
                    span: 6,
                    offset: languageRTL || logoPositionClass === 'logoposition-right' ? 0 : 8,
                  }
                : { span: 12 }
            }
            xs={sectionAlignment === 'middle' ? { span: 6 } : { span: 12 }}
            sm={
              sectionAlignment === 'middle'
                ? {
                    span: 6,
                    offset: languageRTL || logoPositionClass === 'logoposition-right' ? 0 : 8,
                  }
                : { span: 12 }
            }>
            <div
              className="contact-by-values"
              style={helpers.getStyleObj(proposal, 'body1')}
              dangerouslySetInnerHTML={{ __html: byhtml }}
            />

            <h3
              className="contact-by"
              style={helpers.getStyleObj(proposal, 'title1')}
              dangerouslySetInnerHTML={{ __html: namehtml }}
            />
          </Col>
          <Col
            className={`contact-by-values-${sectionAlignment}`}
            xs={12}
            sm={sectionAlignment === 'middle' ? 6 : 12}
            md={sectionAlignment === 'middle' ? 10 : 12}
            lg={sectionAlignment === 'middle' ? 10 : 12}>
            <div>
              <div
                className="contact-by-values"
                style={helpers.getStyleObj(proposal, 'body1')}
                dangerouslySetInnerHTML={{ __html: contacthtml }}
              />

              <h3
                className="contact-by"
                style={helpers.getStyleObj(proposal, 'title1')}
                dangerouslySetInnerHTML={{ __html: emailhtml }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

PublishHeaderSection.defaultProps = {
  name: 'header',
  templateWixPreview: false,
};

PublishHeaderSection.propTypes = {
  proposal: PropTypes.instanceOf(Object).isRequired,
  name: PropTypes.string,
  templateWixPreview: PropTypes.bool,
};

export default PublishHeaderSection;
