/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#5D5AB5"
      d="M17,19.8C17,19.8,17,19.8,17,19.8c-0.4,0-0.7-0.3-0.7-0.6L13.3,6.6l-2.6,7.2c-0.1,0.3-0.4,0.5-0.6,0.5 c-0.3,0-0.6-0.1-0.7-0.4L7.1,9.7l-0.9,2.1c-0.1,0.3-0.4,0.5-0.7,0.5h-4c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8H5l1.3-3 C6.4,7.4,6.7,7.3,7,7.3c0.3,0,0.6,0.1,0.7,0.4l2.2,4l2.9-7.9c0.1-0.3,0.4-0.5,0.8-0.5c0.3,0,0.6,0.3,0.7,0.6l2.9,12.4l1.7-5 c0.1-0.3,0.4-0.5,0.7-0.5h3c0.4,0,0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8H20l-2.3,7C17.6,19.5,17.3,19.8,17,19.8z"
    />
  </svg>
);

const ActivityNotificationIcon = (props) => <Icon component={svg} {...props} />;

export default ActivityNotificationIcon;
