import React from 'react';
import { Switch, Route } from 'react-router-dom';
// import routes
import Login from 'pages/Login';
import Dashboard from 'pages/Dashboard';
import Signup from 'pages/Signup';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import Profile from 'pages/Profile';
import Settings from 'pages/Settings';
import Affiliate from 'pages/Affiliate';
import UsersTeam from 'pages/UsersTeam';
import Integration from 'pages/Integration';
import Proposal from 'pages/Proposal';
import QuickbooksWindow from 'pages/Integration/components/QuickbooksIntegration/QuickbooksWindow';
import XeroWindow from 'pages/Integration/components/XeroIntegration/XeroWindow';
import MondayWindow from 'pages/Integration/components/MondayIntegration/MondayWindow';
import InvalidConnection from 'pages/InvalidConnection/InvalidConnection';
import SlackWindow from 'pages/Integration/components/SlackIntegration/SlackWindow';
import QuickbooksDisconnect from 'pages/Integration/components/QuickbooksIntegration/QuickbooksDisconnect';
import WixAppWindow from 'pages/Integration/components/WixIntegration/WixAppWindow';
import WixWindow from 'pages/Integration/components/WixIntegration/WixWindow';
import FreshooksWindow from 'pages/Integration/components/FreshbooksIntegration/FreshbooksWindow';
import UsersActivity from 'pages/UsersActivity';
import Plans from 'pages/Plans';
import ContentLibrary from 'pages/ContentLibrary';
import Admin from 'pages/Admin';
import ProposalRedirection from 'pages/Proposal/components/ProposalRedirection';
import NotFound from 'pages/NotFound';
import NotFoundAuth from 'pages/NotFoundAuth';
import ProposalDownloadPdf from 'pages/Proposal/components/ProposalDownloadPdf';
// import IconsPage from 'pages/Icons';

import AuthBackdrop from 'components/AuthBackdrop/AuthBackdrop';
// import hoc to pass additional props to routes
import PropsRoute from './PropsRoute';
import PrivateRoute from './PrivateRoute';
import AdminRoute from './AdminRoute';
import Path from './path';

const Routes = (props) => (
  <Switch>
    <PrivateRoute exact path={Path.PROFILE} component={Profile} {...props} />
    <PrivateRoute exact path={Path.SETTINGS} component={Settings} {...props} />
    <PrivateRoute exact path={Path.USERSTEAM} component={UsersTeam} {...props} />
    <PrivateRoute exact path={Path.DASHBOARD} component={Dashboard} {...props} />
    <PrivateRoute exact path={Path.AFFILIATE} component={Affiliate} {...props} />
    <PrivateRoute exact path={Path.INTEGRATION} component={Integration} {...props} />
    <PrivateRoute exact path={Path.STRIPEINTEGRATIONS} component={Integration} {...props} />
    <PrivateRoute exact path={Path.HOME} component={Dashboard} {...props} />
    <PrivateRoute path={Path.PLANS} component={Plans} {...props} />
    <PrivateRoute path={Path.ACTIVITY} component={UsersActivity} {...props} />
    <PrivateRoute path={Path.CONTENT_LIBRARY} component={ContentLibrary} {...props} />
    <PrivateRoute path={Path.WIX_PROPOSAL_EDITOR} component={Proposal} {...props} />
    <PrivateRoute path={Path.WIX_TEMPLATE_EDITOR} component={Proposal} {...props} />
    <AdminRoute path={Path.ADMIN} component={Admin} {...props} />
    <PropsRoute exact path={Path.QUICKBOOKS} component={QuickbooksWindow} {...props} />
    <PropsRoute exact path={Path.XERO} component={XeroWindow} {...props} />
    <PropsRoute exact path={Path.MONDAY} component={MondayWindow} {...props} />
    <PropsRoute exact path={Path.INVALID_CONNECTION} component={InvalidConnection} {...props} />
    <PropsRoute exact path={Path.SLACK} component={SlackWindow} {...props} />
    <PropsRoute exact path={Path.FRESHBOOKS} component={FreshooksWindow} {...props} />
    <PropsRoute
      exact
      path={Path.QUICKBOOKS_DISCONNECT}
      component={QuickbooksDisconnect}
      {...props}
    />
    <PropsRoute path={Path.PROPOSAL(':pid')} component={Proposal} {...props} />
    <PropsRoute path={Path.DRAFT(':pid')} component={Proposal} {...props} />
    <PropsRoute path={Path.TEMPLATE(':tid')} component={Proposal} {...props} />
    <PropsRoute path={Path.SECTION(':sid')} component={Proposal} {...props} />
    <PropsRoute path={Path.PUBLISH_TEMPLATE(':tid')} component={Proposal} {...props} />
    <PropsRoute path={Path.PUBLISH_SECTION(':sid')} component={Proposal} {...props} />
    <PropsRoute path={Path.PUBLISH(':id')} component={Proposal} {...props} />
    <PropsRoute path={Path.CLIENT(':cid')} component={Proposal} {...props} />
    <PropsRoute path={Path.PDF(':cid')} component={Proposal} {...props} />
    <PropsRoute path={Path.WIX_PROPOSAL_PREVIEW} component={Proposal} {...props} />
    <PropsRoute path={Path.WIX_TEMPLATE_PREVIEW} component={Proposal} {...props} />
    <PropsRoute path={Path.ROXY} component={ProposalRedirection} {...props} />
    <PropsRoute path={Path.DOWNLOAD_PDF} component={ProposalDownloadPdf} {...props} />
    <PropsRoute path={Path.WIX} component={WixWindow} {...props} />
    <PropsRoute path={Path.WIX_OAUTH} component={WixAppWindow} {...props} />
    <PropsRoute path={Path.WIX_APP_CONNECT} component={WixAppWindow} {...props} />
    <PropsRoute path={Path.WIX_CONNECT} component={WixWindow} {...props} />
    {/* <PropsRoute path='/icons' component={IconsPage} {...props} /> */}
    <Route
      path={[
        Path.LOGIN,
        Path.SIGNUP,
        Path.FORGOT_PASSWORD,
        Path.RESET_PASSWORD(':token'),
        Path.UPDATE_PASSWORD(':token'),
      ]}
      render={(props) => (
        <AuthBackdrop>
          <Switch>
            <PropsRoute path={Path.LOGIN} component={Login} {...props} />
            <PropsRoute exact path={Path.SIGNUP} component={Signup} {...props} />
            <PropsRoute exact path={Path.FORGOT_PASSWORD} component={ForgotPassword} {...props} />
            <PropsRoute
              exact
              path={Path.RESET_PASSWORD(':token')}
              component={ResetPassword}
              {...props}
            />
            <PropsRoute
              exact
              path={Path.UPDATE_PASSWORD(':token')}
              component={ResetPassword}
              {...props}
            />
            <Route path="*" exact={true} component={NotFoundAuth} />
          </Switch>
        </AuthBackdrop>
      )}
    />
    <Route path="*" exact={true} component={NotFound} />
  </Switch>
);

export default Routes;
