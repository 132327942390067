/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_287_24008)">
      <path
        d="M6.60008 2.82652C7.05897 2.71911 7.52879 2.66541 8.00008 2.66652C12.6667 2.66652 15.3334 7.99985 15.3334 7.99985C14.9287 8.75693 14.4461 9.46968 13.8934 10.1265M9.41341 9.41319C9.23031 9.60968 9.00951 9.76729 8.76418 9.8766C8.51885 9.98591 8.25401 10.0447 7.98547 10.0494C7.71693 10.0542 7.45019 10.0048 7.20115 9.90418C6.95212 9.80359 6.72589 9.65387 6.53598 9.46396C6.34606 9.27404 6.19634 9.04782 6.09575 8.79878C5.99516 8.54975 5.94576 8.283 5.9505 8.01446C5.95524 7.74592 6.01402 7.48108 6.12333 7.23575C6.23264 6.99042 6.39025 6.76962 6.58675 6.58652M11.9601 11.9599C10.8205 12.8285 9.43282 13.3098 8.00008 13.3332C3.33341 13.3332 0.666748 7.99985 0.666748 7.99985C1.49601 6.45445 2.64617 5.10426 4.04008 4.03985L11.9601 11.9599Z"
        stroke="#374151"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M0.666748 0.666504L15.3334 15.3332"
        stroke="#374151"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_287_24008">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const PasswordEyeShutIcon = (props) => <Icon component={svg} {...props} />;

export default PasswordEyeShutIcon;
