import React from 'react';
import { Button, Modal, Col, Row } from 'antd';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import CircleTickIcon from 'components/Icons/CircleTickIcon';

import images from 'constants/images';
import { FEATURES_LIST_1, FEATURES_LIST_2 } from 'constants/index';

import './TrialEndReminder.scss';

const renderFeaturesList = (list) => {
  return list.map((item) => (
    <Row key={item.text} className="feature-list">
      <CircleTickIcon className="feature-list-check-icon" />
      <div className="feature-list-info">
        <span className="feature-list-text">{item.text}</span>
      </div>
    </Row>
  ));
};

const TrialEndReminder = ({ daysLeft, userLockedPopup, setUserLockedPopup }) => {
  const history = useHistory();
  const unLoadStorage = () => {
    window.localStorage.setItem('seen-reminder', true);
  };

  return (
    <>
      <Modal
        className="trial-end-popup reminder"
        centered
        visible={!userLockedPopup}
        onCancel={() => {
          setUserLockedPopup(true);
          unLoadStorage();
        }}
        closable={null}
        footer={null}>
        <div className="popup-left">
          <div className="top-object" />
          <div className="left-object" />
          <div className="bottom-object" />
          <div className="trial-left-header">
            <img className="logo" src={images.LOGO_LIGHT} alt="prospero-logo" />
            <div className="title">
              <p>Professional Proposals.</p>
              <h1>
                Close More Deals.
                <br />
                <span>3x Faster</span>
              </h1>
            </div>
          </div>
          <div className="body">
            <div className="dashboard-img">
              <div className="floating-object" />
              <div className="floating-object" />
              <img src={images.DASHBOARD_IMG_CUT} alt="dashboard" />
            </div>
          </div>
        </div>
        <div className="popup-right">
          <div className="trial-right-header">
            <h3 className="title">Unlock Your Full Potential</h3>
            <p className="sub-title">
              Looks like your free trial is winding down. In just <span>{daysLeft} days</span>,
              you'll be missing out on all the sick features that'll level up your experience.
            </p>
          </div>
          <div className="body">
            <p className="information-header">What you will be missed:</p>
            <Row className="plans-information">
              <Col>{renderFeaturesList(FEATURES_LIST_1)}</Col>
              <Col>{renderFeaturesList(FEATURES_LIST_2)}</Col>
            </Row>
          </div>
          <div className="button-wrapper">
            <Button
              className="upgrade-button"
              type="primary"
              onClick={() => {
                setUserLockedPopup(true);
                unLoadStorage();
                history.push('/plans');
              }}>
              {'Upgrade Now'}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

TrialEndReminder.defaultProps = {
  daysLeft: '',
  userLockedPopup: false,
};

TrialEndReminder.propTypes = {
  daysLeft: PropTypes.string,
  userLockedPopup: PropTypes.bool,
};

export default TrialEndReminder;
