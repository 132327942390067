import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import './Upgrade.scss';

const daystrial = +process.env.REACT_APP_DAYS_TRAIL || 14;

const Upgrade = ({ upgradeValue, everPayed }) => {
  const [percent, setPercent] = useState(138);
  const [clicked, setClicked] = useState(false);

  const onClicked = () => {
    setClicked(true);
    setTimeout(() => {
      setClicked(false);
    }, 2000);
  };

  useEffect(() => {
    setTimeout(() => {
      if(upgradeValue > 0){
        const percent = (138 / 100) * ((upgradeValue / daystrial) * 100);
        setPercent(percent);
      }
    }, 500);
  }, [upgradeValue]);

  return (
    <NavLink
      to="/plans"
      onClick={onClicked}
      className={`upgrade ${clicked ? 'clicked' : ''} ${upgradeValue > 7 ? 'green' : 'red'}`}>
      <div className="circle-progress">
        <svg viewBox="25 25 50 50">
          <circle
            stroke="#E1E4EA"
            className="path"
            strokeWidth="2"
            fill="none"
            r="22"
            cx="50"
            cy="50"
          />
          <circle
            stroke="#01C34F"
            strokeDasharray={`${percent},138`}
            className="path percent"
            fill="none"
            strokeDashoffset="0"
            strokeWidth="2"
            strokeLinecap="round"
            r="22"
            cx="50"
            cy="50"
            style={{ transition: `${(100 - percent) * 15}ms` }}
          />
        </svg>
        <div className="counter">
          {upgradeValue > 0 ? upgradeValue : <img src="/rocket.png" alt="" />}
        </div>
      </div>
      <div>
        {upgradeValue > 0
          ? `Day${upgradeValue > 1 ? 's' : ''} left`
          : `${everPayed ? 'Renew' : 'Upgrade'}`}
      </div>
    </NavLink>
  );
};

Upgrade.defaultProps = {
  isNotActive: false,
  everPayed: 0,
};

Upgrade.propTypes = {
  onPlanClick: PropTypes.func.isRequired,
  upgradeValue: PropTypes.number.isRequired,
  isNotActive: PropTypes.bool,
  everPayed: PropTypes.number,
};

export default Upgrade;
