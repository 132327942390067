import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Row, Col } from 'antd';
import useWindowDimensions from 'hooks/useWindowDimensions';

import WhatsNewLoader from './WhatsNewLoader';
import './WhatsNew.scss';

const WhatsNew = () => {
  const { isMobile } = useWindowDimensions();
  const [changeLogs, setChangeLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.HW_config = {
      selector: '#headway-features',
      account: 'JVD2Dx',
      callbacks: {
        onWidgetReady: function (widget) {
          if (widget.changelogs) {
            setChangeLogs(widget.changelogs);
          }
          if (isMobile) {
            setTimeout(() => {
              setLoading(false);
            }, 2000);
          } else {
            setLoading(false);
          }
        },
      },
    };

    const script = document.createElement('script');
    script.src = 'https://cdn.headwayapp.co/widget.js';
    script.async = true;
    script.id = 'headwayappScript';
    document.body.appendChild(script);

    return () => {
      const element = document.getElementById('headwayappScript');
      if (element) {
        element.parentElement.removeChild(element);
      }
      const badge = document.getElementById('HW_badge_cont');
      if (badge) {
        badge.parentElement.removeChild(badge);
      }
    };
  }, [isMobile]);

  return (
    <div className="whats-new-wrapper">
      <div className="heading-wrap">
        <h1 className="heading">Whats New? 🔥</h1>
        <div className="color-divider" />
      </div>
      <Col span={changeLogs.length ? 24 : 0}>
        <Row id="headway-features">
          {loading ? (
            <WhatsNewLoader />
          ) : (
            changeLogs.length > 0 && (
              <Col>
                {changeLogs.map((log, logIndex) => (
                  <React.Fragment key={logIndex}>
                    <motion.div
                      className="headway-item"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.5, delay: logIndex * 0.3 }}>
                      <Col className="logs-container">
                        <div className="block-group">
                          <div className="pulse-dot"></div>
                          <div className="ant-tag">{log.categories[0]}</div>
                          <a href={log.url} target="_blank" rel="noreferrer">
                            {log.title}
                          </a>
                        </div>
                        <p className="hw-paragraph">{log.content.short}</p>
                      </Col>
                    </motion.div>
                    <div className="divider" />
                  </React.Fragment>
                ))}
              </Col>
            )
          )}
        </Row>
      </Col>
    </div>
  );
};

export default WhatsNew;
