import React from 'react';
import Icon from '@ant-design/icons';

const HelpSvg = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0017 21.5C10.6877 21.5 9.45267 21.2507 8.2965 20.752C7.14033 20.2533 6.13467 19.5766 5.2795 18.7218C4.42433 17.8669 3.74725 16.8617 3.24825 15.706C2.74942 14.5503 2.5 13.3156 2.5 12.0017C2.5 10.6877 2.74933 9.45267 3.248 8.2965C3.74667 7.14033 4.42342 6.13467 5.27825 5.2795C6.13308 4.42433 7.13833 3.74725 8.294 3.24825C9.44967 2.74942 10.6844 2.5 11.9983 2.5C13.3123 2.5 14.5473 2.74933 15.7035 3.248C16.8597 3.74667 17.8653 4.42342 18.7205 5.27825C19.5757 6.13308 20.2528 7.13833 20.7518 8.294C21.2506 9.44967 21.5 10.6844 21.5 11.9983C21.5 13.3123 21.2507 14.5473 20.752 15.7035C20.2533 16.8597 19.5766 17.8653 18.7218 18.7205C17.8669 19.5757 16.8617 20.2528 15.706 20.7518C14.5503 21.2506 13.3156 21.5 12.0017 21.5ZM9.1 19.45L10.5693 16.1038C9.94508 15.8834 9.40633 15.5418 8.953 15.079C8.49967 14.616 8.15383 14.073 7.9155 13.45L4.53075 14.85C4.92058 15.9167 5.5155 16.85 6.3155 17.65C7.1155 18.45 8.04367 19.05 9.1 19.45ZM7.9155 10.55C8.14117 9.927 8.4825 9.38825 8.9395 8.93375C9.3965 8.47925 9.93333 8.13983 10.55 7.9155L9.15 4.53075C8.077 4.93725 7.1405 5.5405 6.3405 6.3405C5.5405 7.1405 4.93725 8.077 4.53075 9.15L7.9155 10.55ZM11.9978 14.8463C12.7878 14.8463 13.46 14.5698 14.0145 14.0168C14.569 13.4638 14.8462 12.7922 14.8462 12.0022C14.8462 11.2122 14.5697 10.54 14.0167 9.9855C13.4637 9.431 12.7922 9.15375 12.0022 9.15375C11.2122 9.15375 10.54 9.43025 9.9855 9.98325C9.431 10.5363 9.15375 11.2078 9.15375 11.9978C9.15375 12.7878 9.43025 13.46 9.98325 14.0145C10.5363 14.569 11.2078 14.8463 11.9978 14.8463ZM14.9 19.45C15.95 19.05 16.8708 18.4542 17.6625 17.6625C18.4542 16.8708 19.05 15.95 19.45 14.9L16.1038 13.4308C15.8923 14.0474 15.557 14.5827 15.098 15.0365C14.639 15.4903 14.1063 15.8397 13.5 16.0845L14.9 19.45ZM16.0845 10.5L19.45 9.1C19.05 8.05 18.4542 7.12917 17.6625 6.3375C16.8708 5.54583 15.95 4.95 14.9 4.55L13.5 7.94625C14.0935 8.17692 14.6114 8.51283 15.0538 8.954C15.4961 9.395 15.8397 9.91033 16.0845 10.5Z"
      fill="#5D5AB5"
    />
  </svg>
);

const HelpIcon = (props) => <Icon component={HelpSvg} {...props} />;

export default HelpIcon;
