import React, { memo, useMemo } from 'react';
import { Row, Col } from 'antd';
import images from 'constants/images';
import './Testmonial.scss';

const testimonialContent = [
  {
    owner: 'Lizzie Karmi',
    position: 'CEO, n.e.f - Business automation',
    content: `From the first day I started using Prospero I couldn't believe it - my proposals' conversion rates increased significantly! Love how easy it is to generate clear and winning proposals.`,
    image: images.TESTIMONIAL_1,
  },
  {
    owner: 'Hadar Chen',
    position: 'Co-founder, Hunt Digital Marketing',
    content: `Prospero is helping us to achieve exhilarating and invigorating results. 
    We're receiving more qualified leads and higher conversion rates, 
    followed by an explicit WOW reaction from our clients. 
    It's a great boost to any business and it's easy to use!`,
    image: images.TESTIMONIAL_3,
  },
  {
    owner: 'Hadar Chen',
    position: 'Co-founder, Hunt Digital Marketing',
    content: `Prospero is helping us to achieve exhilarating and invigorating results.
      We're receiving more qualified leads and higher conversion rates,
      followed by an explicit WOW reaction from our clients.
      It's a great boost to any business and it's easy to use!`,
    image: images.TESTIMONIAL_4,
  },
  {
    owner: 'Almog Ben Atar',
    position: 'Co-founder, Hunt Digital',
    content: `Prospero is helping us to achieve exhilarating and invigorating results.
      We're receiving more qualified leads and higher conversion rates,
      followed by an explicit WOW reaction from our clients.
      It's a great boost to any business and it's easy to use!`,
    image: images.TESTIMONIAL_5,
  },
  {
    owner: 'Itay Verchik',
    position: 'CEO & Marketing Director, IVBS',
    content: `Since we started using Prospero, our closing percentages have risen miraculously 
    and our customers are also impressed by the designed contracts they receive.
    My team has a more orderly follow-up on the contracts and 
    we also know what works better and what less well thanks to Prospero.`,
    image: images.TESTIMONIAL_6,
  },
];

const generateNumber = () => {
  var randNum = [Math.floor(Math.random() * 5)];
  return randNum[0] === 2 ? generateNumber() : randNum;
};

const Testimonial = memo((fromPage) => {
  let randomNumber = useMemo(() => generateNumber(), []);

  if (fromPage.page === 'ForgotPassword' || fromPage.page === 'ResetPassword') {
    randomNumber = 2;
  }
  return (
    <Row>
      <Col className="testimonial-container">
        <div className="testimonial-card">
          <div className="top-object" />
          <div className="bottom-object" />
          <div className="testimonial-content">
            <div className="testimonial-quote">
              <p>&nbsp;</p>
            </div>
            <p className="testimonial-text">{testimonialContent[randomNumber].content}</p>
          </div>
          <div className="testimonial-author">
            <img
              src={testimonialContent[randomNumber].image}
              alt={testimonialContent[randomNumber].owner}
              className="author-image"
            />
            <div className="author-details">
              <h3 className="author-name">{testimonialContent[randomNumber].owner}</h3>
              <p className="author-title">{testimonialContent[randomNumber].position}</p>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
});

export default Testimonial;
