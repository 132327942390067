import React from 'react';
import { motion } from 'framer-motion';

import { authBackdropImages } from 'constants/images';

import './AuthBackdrop.scss';

const AnimatedImageList = ({ images, duration }) => {
  const loopImages = [...images, ...images];

  return (
    <motion.ul
      className="auth-template-images-list"
      initial={{ y: '0%' }}
      animate={{ y: '-100%' }}
      transition={{
        duration,
        ease: 'linear',
        repeat: Infinity,
        repeatType: 'loop',
      }}>
      {loopImages.map((image, index) => (
        <li className={`ticker-template-item ${index < 3 ? 'width-full' : ''}`} key={index}>
          <div className="images-container">
            <div className="background-image-wrapper">
              <img src={image.url} alt={`Template ${index + 1}`} className="background-image" />
            </div>
          </div>
        </li>
      ))}
    </motion.ul>
  );
};

const AuthBackdrop = ({ children }) => {
  return (
    <div className="auth-layout">
      <div className="auth-wrapper">
        <div className="auth-content">{children}</div>
        <div className="auth-backdrop">
          <div className="backdrop-container">
            <motion.div
              className="backdrop-content"
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.15599 }}
              transition={{ duration: 3, delay: 1 }}>
              {authBackdropImages.map((images, index) => (
                <div className="ticker-container" key={index}>
                  <section className="ticker-section">
                    <AnimatedImageList images={images} duration={index % 2 === 0 ? 62 : 68} />
                  </section>
                </div>
              ))}
            </motion.div>
          </div>
        </div>
      </div>
      <div className="auth-footer">
        <div className="footer-links">
          <a href="https://goprospero.com" target="_self" rel="noreferrer">
            Home
          </a>{' '}
          |{' '}
          <a
            href="https://www.iubenda.com/privacy-policy/50076459"
            target="_blank"
            rel="noreferrer">
            Privacy
          </a>{' '}
          |{' '}
          <a href="https://goprospero.com/gdpr" target="_blank" rel="noreferrer">
            GDPR
          </a>{' '}
          |{' '}
          <a href="https://goprospero.com/terms" target="_blank" rel="noreferrer">
            Terms
          </a>
        </div>
      </div>
    </div>
  );
};

export default AuthBackdrop;
