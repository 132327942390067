import utils from 'utils/utils';

const { S3Image } = utils;

const images = {
  LOGO: S3Image('application/logo/', 'logo.svg'),
  LOGO_SMALL: S3Image('application/logo/', 'logo-small.svg'),
  LOGO_LIGHT: S3Image('application/static/', 'ProsperoLogoLight.svg'),
  WIX_ICON: S3Image('application/integrations/', 'wix.svg'),
  STRIPE_ICON: S3Image('application/integrations/', 'stripe.png'),
  ZAPIER_ICON: S3Image('application/integrations/', 'zapier.png'),
  INTEGROMAT_ICON: S3Image('application/integrations/', 'makeintegromat.svg'),
  FRESHBOOKS_ICON: S3Image('application/integrations/', 'freshbooks.png'),
  MONDAY_ICON: S3Image('application/integrations/', 'monday.svg'),
  XERO_ICON: S3Image('application/integrations/', 'xero.svg'),
  MORNING_INVOICE_ICON: S3Image('application/integrations/', 'morninginvoice.svg'),
  SLACK_ICON: S3Image('application/integrations/', 'slackLogo.svg'),
  QUICKBOOKS_ICON: S3Image('application/integrations/', 'quickbooks.png'),
  QUICKBOOKS_LOGO: S3Image('application/integrations/', 'quickbooksLogo.png'),
  QUICKBOOKS_CONNECT_DEFAULT: S3Image('application/integrations/', 'QuickBook_Connect_Default.png'),
  QUICKBOOKS_CONNECT_HOVER: S3Image('application/integrations/', 'QuickBook_Connect_Hover.png'),
  CODES_ICON: S3Image('application/integrations/', 'codes.png'),
  NEW_USER: S3Image('application/integrations/', 'chatway-new-user.png'),
  EXISTING_USER: S3Image('application/integrations/', 'chatway-existing-user.png'),
  PAYPAL_LOGO: S3Image('application/', 'paypal.png'),
  PROPOSAL_FROM_SCRATCH: S3Image('application/', 'proposal-scratch.png'),
  PAYPAL_FROM_TEMPLATES: S3Image('application/', 'proposal-templates.png'),
  FOUNDER: S3Image('application/', 'founder.png'),
  TESTIMONIAL_1: S3Image('application/testimonial/', 'testifier-1.png'),
  TESTIMONIAL_2: S3Image('application/testimonial/', 'testifier-2.png'),
  TESTIMONIAL_3: S3Image('application/testimonial/', 'testifier-3.png'),
  TESTIMONIAL_4: S3Image('application/testimonial/', 'testifier-4.png'),
  TESTIMONIAL_5: S3Image('application/testimonial/', 'testifier-5.png'),
  TESTIMONIAL_6: S3Image('application/testimonial/', 'testifier-6.png'),
  USER_ACTIVITY: S3Image('application/', 'user-activity.png'),
  PLAN: S3Image('application/', 'Hadar+Chen.png'),
  USER_EMPTY: S3Image('application/', 'user-empty.png'),
  SIGNUP_BACKGROUND: S3Image('images/', 'signup-background.jpg'),
  USERWAY_ICON: S3Image('application/integrations/', 'userway.png'),
  DESIGN_IMG_1: S3Image('application/static/', 'card.svg'),
  DESIGN_IMG_2: S3Image('application/static/', 'card4.svg'),
  DESIGN_IMG_3: S3Image('application/static/', 'card3.svg'),
  DESIGN_IMG_4: S3Image('application/static/', 'card2.svg'),
  DESIGN_IMG_5: S3Image('application/static/', 'card5.svg'),
  DASHBOARD_IMG_CUT: S3Image('application/static/cards/', 'Home.svg'),
};

export const authBackdropImages = [
  [
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-13.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-2.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-25.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-3.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-4.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-5.png') },
  ],
  [
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-6.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-7.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-8.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-9.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-10.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-11.png') },
  ],
  [
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-12.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-1.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-14.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-15.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-16.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-17.png') },
  ],
  [
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-24.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-35.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-26.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-27.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-28.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-29.png') },
  ],
  [
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-18.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-20.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-21.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-22.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-23.png') },
  ],
  [
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-30.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-31.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-32.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-33.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-34.png') },
    { url: S3Image('application/auth/', 'Content+Marketing+Proposal+Template-19.png') },
    
  ],
];

export default images;
