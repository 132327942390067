import React from 'react';
import Icon from '@ant-design/icons';

const BellIcon = () => (
  <svg viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#5D5AB5"
      className="filled"
      d="M1.25 16.8845C1.0375 16.8845 0.859417 16.8127 0.71575 16.669C0.571917 16.5252 0.5 16.3469 0.5 16.1343C0.5 15.9218 0.571917 15.7437 0.71575 15.6C0.859417 15.4565 1.0375 15.3848 1.25 15.3848H2.30775V7.923C2.30775 6.57817 2.72283 5.38908 3.553 4.35575C4.383 3.32242 5.44867 2.6615 6.75 2.373V1.75C6.75 1.40283 6.87142 1.10767 7.11425 0.8645C7.35708 0.6215 7.65192 0.5 7.99875 0.5C8.34575 0.5 8.641 0.6215 8.8845 0.8645C9.12817 1.10767 9.25 1.40283 9.25 1.75V2.373C10.5513 2.6615 11.617 3.32242 12.447 4.35575C13.2772 5.38908 13.6923 6.57817 13.6923 7.923V15.3848H14.75C14.9625 15.3848 15.1406 15.4566 15.2843 15.6003C15.4281 15.7441 15.5 15.9223 15.5 16.135C15.5 16.3475 15.4281 16.5256 15.2843 16.6693C15.1406 16.8128 14.9625 16.8845 14.75 16.8845H1.25ZM7.99825 19.6923C7.50075 19.6923 7.07542 19.5153 6.72225 19.1613C6.36892 18.8073 6.19225 18.3817 6.19225 17.8845H9.80775C9.80775 18.3833 9.63058 18.8093 9.27625 19.1625C8.92192 19.5157 8.49592 19.6923 7.99825 19.6923Z"
    />
    <path
      className="outline"
      fill="#05034D"
      d="M0.75 16.3845C0.5375 16.3845 0.359417 16.3127 0.21575 16.169C0.0719168 16.0252 0 15.8469 0 15.6343C0 15.4218 0.0719168 15.2437 0.21575 15.1C0.359417 14.9565 0.5375 14.8848 0.75 14.8848H1.80775V7.423C1.80775 6.07817 2.22283 4.88908 3.053 3.85575C3.883 2.82242 4.94867 2.1615 6.25 1.873V1.25C6.25 0.902833 6.37142 0.607667 6.61425 0.3645C6.85708 0.1215 7.15192 0 7.49875 0C7.84575 0 8.141 0.1215 8.3845 0.3645C8.62817 0.607667 8.75 0.902833 8.75 1.25V1.873C10.0513 2.1615 11.117 2.82242 11.947 3.85575C12.7772 4.88908 13.1923 6.07817 13.1923 7.423V14.8848H14.25C14.4625 14.8848 14.6406 14.9566 14.7843 15.1003C14.9281 15.2441 15 15.4223 15 15.635C15 15.8475 14.9281 16.0256 14.7843 16.1693C14.6406 16.3128 14.4625 16.3845 14.25 16.3845H0.75ZM7.49825 19.1923C7.00075 19.1923 6.57542 19.0153 6.22225 18.6613C5.86892 18.3073 5.69225 17.8817 5.69225 17.3845H9.30775C9.30775 17.8833 9.13058 18.3093 8.77625 18.6625C8.42192 19.0157 7.99592 19.1923 7.49825 19.1923ZM3.30775 14.8848H11.6923V7.423C11.6923 6.26533 11.283 5.27725 10.4645 4.45875C9.64583 3.64008 8.65767 3.23075 7.5 3.23075C6.34233 3.23075 5.35417 3.64008 4.5355 4.45875C3.717 5.27725 3.30775 6.26533 3.30775 7.423V14.8848Z"
    />
  </svg>
);

const NotificationIcon = (props) => <Icon component={BellIcon} {...props} />;

export default NotificationIcon;
