import React from 'react';
import { Col } from 'antd';

const HeadwayItem = ({ paragraphs }) => (
  <div className="headway-item">
    <div className="logs-container">
      <div className="block-group placeload-wrap">
        <div className="pulse-dot placeload-avatar is-small loads"></div>
        <div className="ant-tag content-shape loads"></div>
        <div className="link content-shape loads"></div>
      </div>
      {paragraphs.map((width, index) => (
        <p
          key={index}
          className="hw-paragraph content-shape loads"
          style={width ? { width } : {}}></p>
      ))}
    </div>
  </div>
);

const WhatsNewLoader = () => {
  const items = [
    { paragraphs: ['90%', '25%'] },
    { paragraphs: ['90%', '75%'] },
    { paragraphs: ['90%', '80%', '48%'] },
  ];

  return (
    <Col>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <HeadwayItem {...item} />
          {index < items.length - 1 && <div className="divider" />}
        </React.Fragment>
      ))}
    </Col>
  );
};

export default WhatsNewLoader;
