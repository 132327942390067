import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { NavLink } from 'react-router-dom';

import Path from '../../routes/path';
import images from 'constants/images';
import './HeaderBar.scss';

const { Header } = Layout;

const PublicNav = () => (
  <ul className="right-menu">
    <li key="login" className="nav-item">
      <span className="nav-link">
        <NavLink to="/login">Login</NavLink>
      </span>
    </li>

    <li key="signup" className="nav-item">
      <span className="nav-link">
        <NavLink to="/signup">Signup</NavLink>
      </span>
    </li>
  </ul>
);

const HeaderBar = ({ loggedIn, location, user }) => (
  <>
    {location.pathname !== Path.SIGNUP &&
    location.pathname !== Path.LOGIN &&
    location.pathname !== Path.FORGOT_PASSWORD &&
    location.pathname !== Path.RESET_PASSWORD &&
    location.pathname !== Path.ADMIN &&
    !location.pathname.includes('/w/') &&
    !loggedIn ? (
      <Header className="header">
        <div className="logo">
          {window.location.pathname !== Path.INVALID_CONNECTION ? (
            <NavLink to="/">
              <img src={images.LOGO} alt="prospero-logo" height="20" />
            </NavLink>
          ) : (
            <img src={images.LOGO} alt="prospero-logo" height="20" />
          )}
        </div>
        <PublicNav />
      </Header>
    ) : location.pathname.includes('/w/') ? (
      <Header className="header">
        <div className="logo-small">
          <NavLink to="/">
            <img src={images.LOGO_SMALL} alt="prospero-logo" height="20" />
          </NavLink>
        </div>
      </Header>
    ) : null}
  </>
);

HeaderBar.defaultProps = {
  user: '',
};

HeaderBar.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
};

export default HeaderBar;
