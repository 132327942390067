import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

function useNavigator() {
  const navigate = useHistory();

  return (url, replace) => {
    navigate.push(url, { replace });
    window.scrollTo({ behavior: 'smooth', top: 0 });
  };
}

useNavigator.defaultProps = {
  url: '',
  replace: false,
};

useNavigator.propTypes = {
  url: PropTypes.string.isRequired,
  replace: PropTypes.bool,
};

export default useNavigator;
