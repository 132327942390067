import ProposalIcon from 'components/Icons/ProposalIcon';
import ContentLibraryIcon from 'components/Icons/ContentLibraryIcon';
import IntegrationIcon from 'components/Icons/IntegrationIcon';
// import EmailIcon from 'components/Icons/EmailIcon';
import SettingsIcon from 'components/Icons/SettingsIcon';

export const PROPOSAL_SUBSCRIPTION_TOPIC = 'PROPOSALS';

export const PROPOSALS_LIMIT = 10;
export const TEMPLATES_LIMIT = 15;
export const SECTIONS_LIMIT = 11;

export const SIDE_MENU_ITEMS = [
  {
    id: 0,
    title: 'Proposals',
    icon: ProposalIcon,
    active: true,
    path: '/dashboard',
  },
  {
    id: 1,
    title: 'Library',
    icon: ContentLibraryIcon,
    active: false,
    path: '/contentlibrary',
  },
  {
    id: 2,
    title: 'Integrations',
    icon: IntegrationIcon,
    active: false,
    path: '/integration',
  },
  // {
  //   id: 3,
  //   title: 'Email',
  //   icon: EmailIcon,
  //   active: false,
  //   path: '/email',
  // },
  {
    id: 4,
    title: 'Settings',
    icon: SettingsIcon,
    active: false,
    path: '/settings',
  },
];

export const PROPOSAL_INVOICE_SERVICES = [
  {
    key: 'generate_fresh_book_invoice',
    value: 'FreshBooks Invoices',
    icon: 'FRESHBOOK',
  },
  {
    key: 'generate_quick_book_invoice',
    value: 'QuickBooks Invoices',
    icon: 'QUICKBOOK',
  },
  {
    key: 'generate_xero_invoice',
    value: 'Xero Invoices',
    icon: 'XERO',
  },
  {
    key: 'generate_morning_invoice',
    value: 'morning Invoices',
    icon: 'MORNINGINVOICE',
  },
];

export const PROPOSAL_ITEM_ACTIONS = {
  item_0: { key: 'edit', value: 'Edit' },
  item_1: { key: 'duplicate', value: 'Duplicate' },
  item_2: {
    key: 'invoice submenu',
    value: PROPOSAL_INVOICE_SERVICES,
  },
  item_3: { key: 'save_as_template', value: 'Save as Template' },
  item_4: { key: 'change_to_previous_state', value: 'Change to previous status' },
  item_5: { key: 'download_pdf', value: 'Download PDF' },
  item_9: { key: 'copy_link', value: 'Copy link' },
  item_6: { key: 'share_proposal', value: 'Share Proposal' },
  item_7: { key: 'delete', value: 'Delete' },
  item_8: { key: 'approved_declned', value: 'Approved/Declined' },
};

export const TRASH_PROPOSAL_ITEM_ACTIONS = {
  item_0: { key: 'restore', value: 'Restore' },
  item_1: { key: 'delete', value: 'Delete' },
};

export const PROPOSAL_STATUS_COLORS = {
  DOWNLOADED: { main: '#6079FF', background: '#E7EAFF' },
  DECLINED: { main: '#FF4E4E', background: '#FFE6E6' },
  PUBLISHED: { main: '#6079FF', background: '#E6EAFF' },
  OPENED: { main: '#FF7A00', background: '#FFE8D2' },
  APPROVED: { main: '#01c44f', background: '#DCF7E7' },
  DRAFT: { main: '#636671', background: '#E8E8E8' },
  DELETED: { main: '#FF4E4E', background: '#FFE6E6' },
};

export const PROPOSAL_FILTER_ITEMS = [
  { id: 0, name: 'All Proposals', key: 'All', checked: true },
  { id: 1, name: 'Draft', key: 'edit', checked: false },
  { id: 2, name: 'Published', key: 'published', checked: false },
  { id: 3, name: 'Opened', key: 'seen', checked: false },
  { id: 4, name: 'Approved', key: 'approved', checked: false },
  { id: 5, name: 'Declined', key: 'declined', checked: false },
  { id: 6, name: 'Downloaded', key: 'downloaded', checked: false },
  { id: 7, name: 'Deleted', key: 'deleted', checked: false },
];

export const PROPOSAL_REPORT_FILTER_ITEMS = [
  { id: 0, name: 'This month', key: 'thismonth' },
  { id: 1, name: 'Last month', key: 'lastmonth' },
  { id: 2, name: 'Last 3 month', key: 'last3month' },
  { id: 3, name: 'This year', key: 'thisyear' },
  { id: 4, name: 'Last year', key: 'lastyear' },
  { id: 5, name: 'All time', key: 'alltime' },
];

export const ACTIVITY_FILTER_ITEMS = [
  { id: 0, name: 'All Activities', key: 'All', checked: true },
  { id: 1, name: 'Edited', key: 'edit', checked: false },
  { id: 2, name: 'Created', key: 'created', checked: false },
  { id: 3, name: 'Opened', key: 'opened', checked: false },
  { id: 4, name: 'Published', key: 'published', checked: false },
  { id: 5, name: 'Trashed', key: 'trashed', checked: false },
  { id: 6, name: 'Deleted', key: 'deleted', checked: false },
  { id: 7, name: 'Approved', key: 'approved', checked: false },
  { id: 8, name: 'Declined', key: 'declined', checked: false },
  { id: 9, name: 'Duplicate', key: 'duplicate', checked: false },
  { id: 10, name: 'Shared', key: 'shared', checked: false },
];

export const TEMPLATE_FILTER_ITEMS = [
  { id: 0, name: 'All', key: 'all', checked: true },
  { id: 1, name: 'Disabled', key: 'disabled', checked: false },
  { id: 2, name: 'Created by me', key: 'me', checked: false },
  { id: 3, name: 'Default', key: 'admin', checked: false },
];

export const COVERS_FILTER_ITEMS = [
  { id: 0, name: 'TOP COVERS', key: 'top-cover', checked: true },
  { id: 1, name: 'FULL PAGE COVERS', key: 'full-page', checked: false },
];

export const PROPOSAL_USER_FILTER_ITEMS = [
  { id: 0, name: 'By me', key: 'by_me', checked: true },
  { id: 1, name: 'Shalom', key: 'shalom', checked: false },
  { id: 2, name: 'the saboteur', key: 'sab', checked: false },
];

export const PROPOSAL_STATUS = {
  edit: 'DRAFT',
  pdf: 'DOWNLOADED',
  sent: 'SENT',
  seen: 'OPENED',
  approved: 'APPROVED',
  declined: 'DECLINED',
  published: 'PUBLISHED',
  downloaded: 'DOWNLOADED',
  deleted: 'DELETED',
};

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const DEFAULT_CLIENT_DETAILS = {
  companyName: 'Lonely Pizza',
  clientName: 'Prospect Name',
};

export const RESTRICTED_PAGES = ['/w/', '/d/', '/cd/', '/pd/'];

export const FEATURES_LIST_1 = [
  {
    text: 'Unlimited proposals',
    tooltip: 'Create and send as many proposals as your heart desires.',
  },
  {
    text: 'Proposal analytics',
    tooltip: 'See who viewed your proposal, when, for how long, and from what device.',
  },
  {
    text: 'Integrations',
    tooltip: 'Connect your proposal with Stripe, Quickbooks and other services.',
  },
  {
    text: 'Content Library',
    tooltip: 'Use our text snippets and images in your proposals in a click, or create your own.',
  },
  {
    text: 'Templates',
    tooltip: 'Get started quicker with a ready-made template.',
  },
  {
    text: 'Live chat support',
    tooltip: 'Chat with us if you ever need help.',
  },
];

export const FEATURES_LIST_2 = [
  {
    text: 'Shared content & proposals',
    tooltip: 'Share content and proposals between team members.',
  },
  {
    text: "Users' roles",
    tooltip: 'Control user access to your proposals and content.',
  },
];

export const TRAIL_BENIFITS = {
  item1: [
    'Create unlimited proposals',
    'Use our advanced editor',
    'Improve workflow with integrations',
  ],
  item2: [
    'Robust premade content library',
    'Template banks for premade proposals',
    'Secure projects via digital signature',
  ],
};

export const NO_HEADER_SIDEBAR_ROUTES = [
  '/d/',
  '/cd/',
  '/pd/',
  '/t/',
  '/s/',
  '/w/',
  '/pt/',
  '/ps/',
  '/PDF/',
  '/roxy',
  '/download/pdf',
  '/update-password',
  '/reset-password',
  '/login',
  '/signup',
  '/xero',
  '/monday',
  '/wix',
  '/wix-connect',
  '/wix-app-connect',
  '/proposal-editor',
  '/template-editor',
  '/proposal-preview',
  '/template-preview',
];

export const POLLING_INTERVAL = {
  // in seconds
  dashboard: parseInt(process.env.REACT_APP_POLLING_INTERVAL_DASHBOARD) || 5,
  proposal: parseInt(process.env.REACT_APP_POLLING_INTERVAL_PROPOSAL) || 1,
};

export const LOCKED_PAGES = ['/integration', '/users', '/activity'];

export const ContactOptions = [
  {
    text: 'Email',
    value: 'email',
  },
  {
    text: 'Call',
    value: 'call',
  },
  {
    text: 'WhatsApp',
    value: 'whatsapp',
  },
];

export const DEFAULT_CONTACTS_OPTIONS = [
  {
    option: 'email',
    value: '',
    enabled: false,
  },
  {
    option: 'call',
    value: '',
    enabled: false,
  },
  {
    option: 'whatsapp',
    value: '',
    enabled: false,
  },
];

export const SLACK_NOTIFICATION = [
  {
    text: 'Proposal published',
    action: 'published',
    selected: false,
  },
  {
    text: 'Proposal opened',
    action: 'open',
    selected: false,
  },
  {
    text: 'Proposal approved',
    action: 'approved',
    selected: false,
  },
  {
    text: 'Proposal declined',
    action: 'declined',
    selected: false,
  },
];

export const ELEMENTS_VARIABLES = {
  client: {
    firstName: {
      count: 0,
      value: '',
    },
    lastName: {
      count: 0,
      value: '',
    },
    fullName: {
      count: 0,
      value: '',
    },
    email: {
      count: 0,
      value: '',
    },
    phone: {
      count: 0,
      value: '',
    },
    company: {
      count: 0,
      value: '',
    },
    VAT: {
      count: 0,
      value: '',
    },
    streetAddress: {
      count: 0,
      value: '',
    },
    city: {
      count: 0,
      value: '',
    },
    postalCode: {
      count: 0,
      value: '',
    },
    state: {
      count: 0,
      value: '',
    },
    country: {
      count: 0,
      value: '',
    },
  },
  proposal: {
    name: {
      count: 0,
      value: '',
    },
    title: {
      count: 0,
      value: '',
    },
    number: {
      count: 0,
      value: '',
    },
    quote: {
      count: 0,
      value: '',
    },
    discount: {
      count: 0,
      value: '',
    },
    subTotal: {
      count: 0,
      value: '',
    },
    tax: {
      count: 0,
      value: '',
    },
    total: {
      count: 0,
      value: '',
    },
    sentDate: {
      count: 0,
      value: '',
    },
    createdDate: {
      count: 0,
      value: '',
    },
    expireDate: {
      count: 0,
      value: '',
    },
  },
  sender: {
    firstName: {
      count: 0,
      value: '',
    },
    lastName: {
      count: 0,
      value: '',
    },
    fullName: {
      count: 0,
      value: '',
    },
    email: {
      count: 0,
      value: '',
    },
    phone: {
      count: 0,
      value: '',
    },
    company: {
      count: 0,
      value: '',
    },
    VAT: {
      count: 0,
      value: '',
    },
  },
  sender2: {
    firstName: {
      count: 0,
      value: '',
    },
    lastName: {
      count: 0,
      value: '',
    },
    fullName: {
      count: 0,
      value: '',
    },
    email: {
      count: 0,
      value: '',
    },
    phone: {
      count: 0,
      value: '',
    },
  },
  custom: {},
};

export const DEFAULT_TEMPLATE_PRICING = (configText) => ({
  lineItems: [
    {
      id: 'bd96d30f-62ff-42d5-bc16-6558f8b59346',
      quantity: '1',
      name: configText('product 1', true),
      description: configText('default product description', true),
      price: '45',
      pricesBreakdown: {
        totalPriceAfterTax: '37.89',
        taxDetails: {
          taxableAmount: '37.89',
          taxGroupId: '88dbfe76-e329-475b-a470-4607e20bead6',
          taxRate: '0.34',
          totalTax: '9.61',
          taxName: configText('tax text', true),
          rateBreakdown: [
            {
              name: '',
              rate: '0.34',
              tax: '9.61',
              taxableAmount: '28.28',
            },
          ],
        },
        totalDiscount: '7.11',
        price: '45',
        priceBeforeDiscounts: '45',
        lineItemPrice: '45',
      },
    },
    {
      id: '341adfab-abc9-43c0-ac33-a36d2ce41a7b',
      quantity: '1',
      name: configText('product 2', true),
      description: configText('default product description', true),
      price: '50',
      pricesBreakdown: {
        totalPriceAfterTax: '42.11',
        taxDetails: {
          taxableAmount: '42.11',
          taxGroupId: '215238eb-22a5-4c36-9e7b-e7c08025e04e',
          taxRate: '0.1',
          totalTax: '3.83',
          taxName: configText('tax text', true),
          rateBreakdown: [
            {
              name: '',
              rate: '0.1',
              tax: '3.83',
              taxableAmount: '38.28',
            },
          ],
        },
        totalDiscount: '7.89',
        price: '50',
        priceBeforeDiscounts: '50',
        lineItemPrice: '50',
      },
    },
  ],
  currencyCode: 'USD',
  calculatedTotals: {
    priceSummary: {
      subtotal: '81.56',
      shipping: '0',
      tax: '13.44',
      discount: '15',
      total: '80',
      additionalFees: '0',
    },
    taxSummary: {
      taxableAmount: '66.56',
      totalTax: '13.44',
      taxName: configText('tax text', true),
      rateBreakdown: [],
    },
    taxIncludedInPrice: true,
  },
  paymentSettings: {
    sendPaymentRequestAutomatically: true,
    type: 'MULTIPLE_PAYMENT',
    multiplePaymentSchedule: {
      items: [
        {
          dueDateOffset: 7,
          issueDateOffset: 0,
          amount: '40',
          percentage: '50.0',
          title: configText('payment 1', true),
          paymentStatus: 'UNSCHEDULED',
          paymentScheduleId: 'aca0fcf0-7a03-4625-8fd2-b922d4f50e4d',
          paymentMethod: 'INVOICE',
        },
        {
          dueDateOffset: 7,
          issueDateOffset: 30,
          amount: '40',
          percentage: '50.0',
          title: configText('payment 2', true),
          paymentStatus: 'UNSCHEDULED',
          paymentScheduleId: 'e4b90c2b-3b10-47d6-9a3f-1eabf6d9b2e1',
          paymentMethod: 'INVOICE',
        },
      ],
      usePercentage: true,
    },
  },
  appliedDiscounts: [
    {
      lineItemIds: [],
      discountType: 'GLOBAL',
    },
  ],
  additionalFees: [],
});

export const PREFIX_NOT_ALLOWED = ['app', 'staging', 'test'];

export const testimonial = {
  data: { name: '', text: '' },
  background: '#fbfbfd',
  color: '#4A4A4A',
};

export const DEFAULT_TEMPLATE_VARIABLES = {
  client: {
    fullName: {
      count: 0,
      value: 'Kat Jones (Demo)',
    },
  },
  proposal: {
    name: {
      count: 0,
      value: 'Basic Proposal Template',
    },
    createdDate: {
      count: 0,
      value: '15-01-2024',
    },
  },
  sender: {
    fullName: {
      count: 0,
      value: 'Jon Doe (Demo)',
    },
    email: {
      count: 0,
      value: 'jon@email.com',
    },
  },
};

export const CHATWAY_NEW_USER_REDIRECT_URL = 'https://go.chatway.app/register?utm_source=prospero';

export const FOOTER_VARIABLES = [
  { id: 0, name: '{{proposal.title}}', key: '{{proposal.title}}', checked: true },
  { id: 1, name: '{{sender.company}}', key: '{{sender.company}}', checked: false },
  { id: 2, name: '{{client.fullName}}', key: '{{client.fullName}}', checked: false },
  { id: 3, name: '{{client.company}}', key: '{{client.company}}', checked: false },
  { id: 4, name: '{{TodayDate}}', key: '{{TodayDate}}', checked: false },
];
