import React from 'react';
import Icon from '@ant-design/icons';

const ContentIcon = () => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 21C2.45 21 1.97917 20.8042 1.5875 20.4125C1.19583 20.0208 1 19.55 1 19V7C1 6.71667 1.09583 6.47917 1.2875 6.2875C1.47917 6.09583 1.71667 6 2 6C2.28333 6 2.52083 6.09583 2.7125 6.2875C2.90417 6.47917 3 6.71667 3 7V19H19C19.2833 19 19.5208 19.0958 19.7125 19.2875C19.9042 19.4792 20 19.7167 20 20C20 20.2833 19.9042 20.5208 19.7125 20.7125C19.5208 20.9042 19.2833 21 19 21H3ZM7 17C6.45 17 5.97917 16.8042 5.5875 16.4125C5.19583 16.0208 5 15.55 5 15V4C5 3.45 5.19583 2.97917 5.5875 2.5875C5.97917 2.19583 6.45 2 7 2H11.175C11.4417 2 11.6958 2.05 11.9375 2.15C12.1792 2.25 12.3917 2.39167 12.575 2.575L14 4H21C21.55 4 22.0208 4.19583 22.4125 4.5875C22.8042 4.97917 23 5.45 23 6V15C23 15.55 22.8042 16.0208 22.4125 16.4125C22.0208 16.8042 21.55 17 21 17H7ZM13.25 11.5L12.1 10C12 9.86667 11.8667 9.8 11.7 9.8C11.5333 9.8 11.4 9.86667 11.3 10L9.625 12.2C9.49167 12.3667 9.47083 12.5417 9.5625 12.725C9.65417 12.9083 9.80833 13 10.025 13H17.975C18.1917 13 18.3458 12.9083 18.4375 12.725C18.5292 12.5417 18.5083 12.3667 18.375 12.2L15.95 9.025C15.85 8.89167 15.7167 8.825 15.55 8.825C15.3833 8.825 15.25 8.89167 15.15 9.025L13.25 11.5Z"
      fill="#5D5AB5"
      className="filled"
    />
    <path
      d="M3.30775 20.3845C2.80908 20.3845 2.38308 20.2079 2.02975 19.8547C1.67658 19.5016 1.5 19.0757 1.5 18.577V7.2885C1.5 7.07567 1.57183 6.8975 1.7155 6.754C1.859 6.61033 2.03717 6.5385 2.25 6.5385C2.46283 6.5385 2.641 6.61033 2.7845 6.754C2.92817 6.8975 3 7.07567 3 7.2885V18.577C3 18.6667 3.02883 18.7403 3.0865 18.798C3.14417 18.8557 3.21792 18.8845 3.30775 18.8845H18.75C18.9628 18.8845 19.141 18.9563 19.2845 19.1C19.4282 19.2435 19.5 19.4217 19.5 19.6345C19.5 19.8473 19.4282 20.0256 19.2845 20.1693C19.141 20.3127 18.9628 20.3845 18.75 20.3845H3.30775ZM6.80775 16.8845C6.30892 16.8845 5.88292 16.7079 5.52975 16.3548C5.17658 16.0016 5 15.5757 5 15.077V4.30775C5 3.80908 5.17658 3.38308 5.52975 3.02975C5.88292 2.67658 6.30892 2.5 6.80775 2.5H11.0402C11.2814 2.5 11.5132 2.54683 11.7355 2.6405C11.958 2.734 12.1512 2.86283 12.3152 3.027L13.7885 4.5H20.6923C21.1909 4.5 21.6169 4.67658 21.9703 5.02975C22.3234 5.38308 22.5 5.80908 22.5 6.30775V15.077C22.5 15.5757 22.3234 16.0016 21.9703 16.3548C21.6169 16.7079 21.1909 16.8845 20.6923 16.8845H6.80775ZM6.80775 15.3848H20.6923C20.7821 15.3848 20.8558 15.3558 20.9135 15.298C20.9712 15.2403 21 15.1667 21 15.077V6.30775C21 6.21792 20.9712 6.14417 20.9135 6.0865C20.8558 6.02883 20.7821 6 20.6923 6H13.175L11.175 4H6.80775C6.71792 4 6.64417 4.02883 6.5865 4.0865C6.52883 4.14417 6.5 4.21792 6.5 4.30775V15.077C6.5 15.1667 6.52883 15.2403 6.5865 15.298C6.64417 15.3558 6.71792 15.3848 6.80775 15.3848ZM13.0962 11.9615L11.8885 10.4615C11.7948 10.3475 11.6759 10.2905 11.5317 10.2905C11.3874 10.2905 11.2685 10.3507 11.175 10.4712L9.798 12.277C9.6775 12.4308 9.65833 12.5898 9.7405 12.7537C9.8225 12.9179 9.959 13 10.15 13H17.6578C17.8488 13 17.9853 12.9179 18.0673 12.7537C18.1493 12.5898 18.1333 12.4308 18.0193 12.277L15.8345 9.3905C15.741 9.27 15.6205 9.20975 15.473 9.20975C15.3257 9.20975 15.2052 9.27 15.1115 9.3905L13.0962 11.9615Z"
      fill="#05034D"
      className="outline"
    />
  </svg>
);

const ContentLibrary = (props) => <Icon component={ContentIcon} {...props} />;

export default ContentLibrary;
