import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Empty, Row, Col } from 'antd';

import Path from 'routes/path';
import images from 'constants/images';

import './NotFoundAuth.scss';

const NotFoundAuth = ({ description }) => {
  useEffect(() => {
    document.title = 'Prospero - Not Found';
  });

  return (
    <Row className="forgot-container">
      <Col className="forgot-left not-found">
        <Row className="header-forgot">
          <Col className="logo">
            <NavLink to={Path.HOME}>
              <img src={images.LOGO} alt="prospero-logo" height="20" />
            </NavLink>
          </Col>
        </Row>
        <div className="content-wrapper">
          <div className="heading-wrap">
            <Empty description={''} />
            <h1 className="heading">{description}</h1>
            <div className="forgot-link">
              Take me back to <NavLink to={Path.LOGIN}>Login</NavLink>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

NotFoundAuth.defaultProps = {
  description: <span>Page Not Found</span>,
};

NotFoundAuth.propTypes = {
  description: PropTypes.instanceOf(Object),
};

export default NotFoundAuth;
