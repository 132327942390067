import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Col, Tabs, Row } from 'antd';

import Sortlist from '../Sortlist/Sortlist';
import TotalOptions from './components/TotalOptions';
import Totals from './components/Totals';
import helpers from 'helpers/proposal';
import DraggableTabs from './components/DraggableTabs';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import Input from 'components/Input';
import utils from 'utils/utils';
import { sanitizeString } from 'utils/xss';
import AllOptionsDrawer from './components/AllOptionsDrawer';
import TitleEditModal from './components/TitleEditModal';

const { TabPane } = Tabs;

const ChoosenTotals = (props) => {
  const {
    state: { optionsDeleteModal, stab },
    listChanged,
    price,
    tabs,
    prop,
    currency,
    config,
    configText,
    save,
    editstrategy,
    dispatch,
    isWizard,
    handleState,
    saveUser,
  } = props;

  const [openDrawer, setOpenDrawer] = useState(false);
  const [showDrawerTotals, setShowDrawerTotals] = useState(false);
  const [visible, setVisible] = useState(false);
  const [discountBtn, setDiscountBtn] = useState(false);
  const [taxBtn, setTaxBtn] = useState(false);
  const [discountVisible, setDiscountVisible] = useState(discountBtn);
  const [taxVisible, setTaxVisible] = useState(taxBtn);
  const clickDiscountBtn = (clicked) => {
    setDiscountBtn(!clicked);
  };
  const clickTaxBtn = (clicked) => {
    if (openDrawer) {
      setShowDrawerTotals(!clicked);
      setTaxBtn(false);
      setTaxVisible(false);
      return;
    }
    setTaxBtn(!taxBtn);
    setTaxBtn(!clicked);
  };

  const calculateDiscount = ({ chosen, curr, totes, totespredis, subtvalue, discount }) => {
    if (prop.priceSeperator === '.') {
      totes = Number(totes?.toString().replace(',', '.')) || 0;
      totespredis = Number(totespredis?.toString().replace(',', '.')) || 0;
      subtvalue = Number(subtvalue?.toString().replace(',', '.')) || 0;
    } else {
      totes = Number(totes?.toString()) || 0;
      totespredis = Number(totespredis?.toString()) || 0;
      subtvalue = Number(subtvalue?.toString()) || 0;
    }
    const dis = discount || chosen ? chosen[stab]?.discount : 0;
    const fdisvalraw = dis && helpers.parseFloatCurr({ prop, x: dis.val, curr });
    const fdisval = dis && Math.abs(fdisvalraw) * (dis.type ? dis.type : fdisvalraw < 0 ? -1 : 1);
    if (fdisval) {
      if (dis.unit === '%') {
        totes += totes * (fdisval / 100);
        if (prop.pricing.strategy === 'value') {
          subtvalue = price ? totespredis : subtvalue / (1 - (-1 * fdisval) / 100);
        }
      } else {
        totes += fdisval;
        if (prop.pricing.strategy === 'value') {
          subtvalue = price ? totespredis : subtvalue - fdisval;
        }
      }
    }
    return [totes, subtvalue];
  };

  const calculateTax = ({ chosen, curr, totes, totespredis, subtvalue, taxes }) => {
    if (prop.priceSeperator === '.') {
      totes = Number(totes?.toString().replace(',', '.')) || 0;
      totespredis = Number(totespredis?.toString().replace(',', '.')) || 0;
      subtvalue = Number(subtvalue?.toString().replace(',', '.')) || 0;
    } else {
      totes = Number(totes?.toString()) || 0;
      totespredis = Number(totespredis?.toString()) || 0;
      subtvalue = Number(subtvalue?.toString()) || 0;
    }
    const tax = taxes || chosen ? chosen[stab]?.tax : 0;
    const ftaxvalraw = tax && helpers.parseFloatCurr({ prop, x: tax.val, curr });
    const ftaxval = tax && Math.abs(ftaxvalraw) * (tax.type ? tax.type : ftaxvalraw < 0 ? -1 : 1);

    if (ftaxval) {
      if (tax.unit === '%') {
        totes += totes * (ftaxval / 100);
        if (prop.pricing.strategy === 'value') {
          subtvalue = price ? totespredis : subtvalue / (1 - (-1 * ftaxval) / 100);
        }
      } else {
        totes += ftaxval;
        if (prop.pricing.strategy === 'value') {
          subtvalue = price ? totespredis : subtvalue - ftaxval;
        }
      }
    }

    return [totes, subtvalue];
  };

  const toggleDiscount = () => {
    const chosen = _.merge({}, props.chosen);
    if (!openDrawer) {
      clickDiscountBtn(discountBtn);
      setDiscountBtn(!discountBtn);
    }

    if (chosen[stab]) {
      if (!chosen[stab].discount) {
        chosen[stab].discount = {
          text: configText('discount text'),
          val: 5,
          unit: '%',
          type: -1,
        };
        chosen[stab].subtotal = true;
        if (editstrategy === 'value') {
          const percentageValue = Math.round(((5 / 100) * chosen[stab].total).toFixed(2));
          chosen[stab].total -= percentageValue;
        }
      } else {
        delete chosen[stab].discount;
        if (editstrategy === 'value') {
          chosen[stab].total = props.totalValue;
        }
      }
      const curr = prop.curr || currency || configText('currency');

      let totes = chosen[stab]?.subtotalValue ? Number(chosen[stab]?.subtotalValue) : 0;

      [totes] = calculateDiscount({
        chosen,
        curr,
        totes: totes,
        totespredis: {},
        subtvalue: 0,
        discount: chosen[stab].discount,
      });

      [totes] = calculateTax({
        chosen,
        curr,
        totes: totes,
        totespredis: {},
        subtvalue: 0,
      });

      chosen[stab].total = Number(totes).toFixed(2);

      dispatch({ type: 'setChosen', chosen });
    }
  };

  const toggleRoundedTotal = () => {
    if (chosen[stab]) {
      if (!chosen[stab].roundTotal) {
        chosen[stab].roundTotal = true;
      } else {
        chosen[stab].roundTotal = false;
      }
      dispatch({ type: 'setChosen', chosen });
    }
  };

  const toggleTax = () => {
    const chosen = _.merge({}, props.chosen);

    if (chosen[stab]) {
      if (!chosen[stab].tax) {
        chosen[stab].tax = {
          text: configText('tax text'),
          val: 5,
          unit: '%',
          type: 1,
        };
        chosen[stab].subtotal = true;
        if (editstrategy === 'value') {
          const percentageValue = Math.round(((5 / 100) * chosen[stab].total).toFixed(2));
          chosen[stab].total += percentageValue;
        }
      } else {
        delete chosen[stab].tax;
        if (editstrategy === 'value') {
          chosen[stab].total = props.totalValue;
        }
      }
      const curr = prop.curr || currency || configText('currency');

      let totes = chosen[stab]?.subtotalValue ? Number(chosen[stab]?.subtotalValue) : 0;

      [totes] = calculateDiscount({
        chosen,
        curr,
        totes: totes,
        totespredis: {},
        subtvalue: 0,
      });

      [totes] = calculateTax({
        chosen,
        curr,
        totes: totes,
        totespredis: {},
        subtvalue: 0,
        taxes: chosen[stab].tax,
      });
      chosen[stab].total = Number(totes).toFixed(2);

      if (!chosen[stab].tax) {
        chosen[stab].subtotalBeforeTax = false;
      }
      dispatch({ type: 'setChosen', chosen });
    }
  };

  const toggleQuantity = () => {
    const chosen = _.merge({}, props.chosen);

    if (chosen[stab]) {
      if (!chosen[stab].quantity) {
        chosen[stab].quantity = true;
        chosen[stab].subtotal = true;
      } else {
        delete chosen[stab].quantity;
      }

      dispatch({ type: 'setChosen', chosen });
    }
  };

  const toggleSelectable = () => {
    const chosen = _.merge({}, props.chosen);

    if (chosen[stab]) {
      if (!chosen[stab].selectable) {
        chosen[stab].selectable = true;
        chosen[stab].subtotal = true;
      } else if (chosen[stab].selectable === true) {
        chosen[stab].selectable = 'locked';
      } else {
        delete chosen[stab].selectable;
      }

      dispatch({ type: 'setChosen', chosen });
    }
  };

  const toggleSubtotal = () => {
    return () => {
      const chosen = _.merge({}, props.chosen);
      chosen[stab].subtotal = !chosen[stab].subtotal;
      dispatch({ type: 'setChosen', chosen });
    };
  };

  const toggleSubtotalBeforeTax = () => {
    return () => {
      const chosen = _.merge({}, props.chosen);
      // if tax is not enabled then dont allow this
      if (!chosen[stab].tax) {
        return;
      }
      chosen[stab].subtotalBeforeTax = !chosen[stab].subtotalBeforeTax;
      dispatch({ type: 'setChosen', chosen });
    };
  };

  const toggleTotal = () => {
    return () => {
      const chosen = _.merge({}, props.chosen);
      if (typeof chosen[stab].showTotal === 'undefined') {
        chosen[stab].showTotal = false;
      } else {
        chosen[stab].showTotal = !chosen[stab].showTotal;
      }
      dispatch({ type: 'setChosen', chosen });
    };
  };

  const toggleStructured = () => {
    return () => {
      const chosen = _.merge({}, props.chosen);
      const keys = Object.keys(chosen);
      const value =
        typeof chosen[keys[0]].structured === 'undefined' ? true : !chosen[keys[0]].structured;

      // added structured to all 'OPTION 1,2,3'
      keys.forEach((key) => {
        chosen[key].structured = value;
      });

      dispatch({ type: 'setChosen', chosen });
    };
  };

  const changeTotalText = () => {
    const chosen = _.merge({}, props.chosen);
    return (e) => {
      const v = e.target.value;
      chosen[stab].totalText = v;
      dispatch({ type: 'setChosen', chosen });
    };
  };

  const changeSubtotalText = () => {
    const chosen = _.merge({}, props.chosen);
    return (e) => {
      const v = e.target.value;
      chosen[stab].subtotalText = v;
      dispatch({ type: 'setChosen', chosen });
    };
  };

  const changesubtotalBeforeTaxText = (prefix = '') => {
    const chosen = _.merge({}, props.chosen);
    return (e) => {
      const v = e.target.value;
      chosen[stab][`subtotalBeforeTaxText${prefix}`] = v;
      dispatch({ type: 'setChosen', chosen });
    };
  };

  // on subtotal value change in total component
  const changeSubtotalValue = () => {
    const chosen = _.merge({}, props.chosen);

    if (chosen[stab]) {
      return (e) => {
        const v =
          prop.priceSeperator === '.'
            ? e?.target?.value.replace(curr, '').replace(/[^0-9,]/g, '')
            : e?.target?.value.replace(curr, '').replace(/[^0-9.]/g, '');
        const subtvalue = helpers.onlyNumSeperator({ prop, x: v, curr });
        let totes = subtvalue;

        // make sure discount value < subtvalue (discount unit is not %, discount value > subtotal)
        if (
          chosen[stab].discount &&
          chosen[stab]?.discount?.unit !== '%' &&
          chosen[stab]?.discount?.val > subtvalue
        ) {
          chosen[stab].discount.val = subtvalue;
        }

        [totes] = calculateDiscount({ chosen, curr, totes, totespredis: {}, subtvalue: 0 });
        [totes] = calculateTax({ chosen, curr, totes, totespredis: {}, subtvalue: 0 });

        chosen[stab].subtotalValue = subtvalue;
        chosen[stab].total = totes;

        dispatch({ type: 'setChosen', chosen });
      };
    }
    return () => {};
  };

  const calcDiscPrice = (price, discount, symbol, isDotSeparator) => {
    if (isDotSeparator) {
      discount = Number(String(discount).replaceAll(',', '.'));
      price = Number(String(price).replaceAll(',', '.'));
    }
    if (discount && price) {
      if (symbol === '%') {
        if (discount >= 100) {
          if (discount > 100) return price;
        }
        price = price - price * (discount / 100);
      } else {
        price = price - discount;
      }
    }
    return price;
  };

  const changeDiscountText = () => {
    const chosen = _.merge({}, props.chosen);
    return (e) => {
      const v = e.target.value;
      chosen[stab].discount.text = v;
      dispatch({ type: 'setChosen', chosen });
    };
  };

  const changeTaxText = () => {
    const chosen = _.merge({}, props.chosen);
    return (e) => {
      const v = e.target.value;
      chosen[stab].tax.text = v;
      dispatch({ type: 'setChosen', chosen });
    };
  };

  const calculateSubTotal = (tempChosen) => {
    tempChosen = tempChosen || chosen[stab];

    if (prop?.pricing?.strategy) {
      // calculate sub ttoal of all items in the list/manual input

      if (prop.pricing.strategy === 'value') {
        // getting the subtotal value from input
        return tempChosen?.subtotalValue || 0;
      } else {
        // add all price, or add all price * items
        if (tempChosen?.list) {
          let totes = 0;
          tempChosen.list.forEach((item) => {
            const discVal = item?.discount?.value || 0;
            const discUnit = item?.discount?.unit || '%';

            const price =
              prop.priceSeperator === '.'
                ? item?.currentPrice
                  ? String(item?.currentPrice)?.replaceAll(',', '.')
                  : String(item?.price)?.replaceAll(',', '.')
                : item?.currentPrice
                ? String(item?.currentPrice)
                : item?.price;

            const disc =
              prop.priceSeperator === '.' ? String(discVal)?.replaceAll(',', '.') : discVal;

            let subTotal = 0;

            if (tempChosen.selectable) {
              if (item.checked) {
                subTotal = calcDiscPrice(
                  parseFloat(price * (tempChosen?.quantity ? item.items || 1 : 1)),
                  parseFloat(
                    disc * (discUnit !== '%' && tempChosen?.quantity ? item.items || 1 : 1)
                  ),
                  discUnit,
                  prop.priceSeperator === '.'
                );
              } else {
                subTotal = 0;
              }
            } else {
              subTotal = calcDiscPrice(
                parseFloat(price * (tempChosen?.quantity ? item.items || 1 : 1)),
                parseFloat(disc * (discUnit !== '%' && tempChosen?.quantity ? item.items || 1 : 1)),
                discUnit,
                prop.priceSeperator === '.'
              );
            }
            totes += Number(subTotal) || 0;
          });
          return totes;
        }
        return 0;
      }
    }
    return 0;
  };

  // on tab change (table/value)
  useEffect(() => {
    if (prop?.pricing?.strategy) {
      if (!chosen[stab]) {
        return;
      }

      let isChanged = false;
      const subTotal = calculateSubTotal();

      // make sure discount < subtotal on tab change
      if (chosen[stab]?.discount?.val > subTotal) {
        chosen[stab].discount.val = subTotal;
        isChanged = true;
      }

      if (chosen[stab]?.total) {
        chosen[stab].total = (subTotal - (chosen[stab]?.discount?.val || 0)).toFixed(2);
        isChanged = true;
      }

      if (isChanged) {
        dispatch({ type: 'setChosen', chosen });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prop?.pricing?.strategy]);

  // change discount value in total component
  const changeDiscountVal = () => {
    const curr = prop.curr || currency || configText('currency');
    const chosen = _.merge({}, props.chosen);

    if (chosen[stab]) {
      return (e) => {
        const dis = chosen[stab].discount;
        const v =
          prop.priceSeperator === '.'
            ? e?.target?.value.replace(/[^0-9,]/g, '')
            : e?.target?.value.replace(/[^0-9.]/g, '');
        let val = helpers.onlyNumSeperator({ prop, x: v, curr });

        if (typeof val === 'string' && val.includes('-')) {
          val = val.replace('-', ''); //removed - sign
        }
        let fval = helpers.parseFloatCurr({ prop, x: val, curr });

        if (utils.checkNan(v, prop.priceSeperator === '.')) {
          return;
        } else if (fval < 0) {
          fval *= -1; // convert to positive number
        }

        if (Number(fval) > 100 && dis.unit === '%') {
          return;
        }

        if (!chosen[stab].selectable) {
          // calculate subtotal
          const subTotal = calculateSubTotal();

          // if unit is $, and discount > subtotal
          if (dis.unit !== '%' && fval > subTotal) {
            fval = 0;
          } else {
            dis.val = v;
          }
          let totes = subTotal;

          [totes] = calculateDiscount({
            chosen,
            curr,
            totes: totes,
            totespredis: {},
            subtvalue: subTotal,
            discount: dis,
          });

          [totes] = calculateTax({
            chosen,
            curr,
            totes: totes,
            totespredis: {},
            subtvalue: subTotal,
          });

          chosen[stab].discount = dis;
          chosen[stab].total = Number(totes).toFixed(2);

          // to make sure discount is not > than total price

          if (dis?.unit !== '%' && subTotal < dis?.val) {
            chosen[stab].discount.val = 0;
          }
        } else {
          dis.val = v;
          chosen[stab].discount = dis;
        }

        dispatch({ type: 'setChosen', chosen });
      };
    }
    return () => {};
  };

  const changeTaxVal = () => {
    const curr = prop.curr || currency || configText('currency');
    const chosen = _.merge({}, props.chosen);

    if (chosen[stab]) {
      return (e) => {
        const { tax } = chosen[stab];
        const v =
          prop.priceSeperator === '.'
            ? e?.target?.value.replace(/[^0-9,]/g, '')
            : e?.target?.value.replace(/[^0-9.]/g, '');
        const val = helpers.onlyNumSeperator({ prop, x: v, curr });
        const fval = helpers.parseFloatCurr({ prop, x: val, curr });
        if (utils.checkNan(v, prop.priceSeperator === '.')) {
          return;
        }
        if (fval > 99 && tax.unit === '%') {
          return;
        }
        tax.val = v;

        let totes = chosen[stab].subtotalValue ? Number(chosen[stab].subtotalValue) : 0;

        [totes] = calculateDiscount({
          chosen,
          curr,
          totes: totes,
          totespredis: {},
          subtvalue: 0,
        });

        [totes] = calculateTax({
          chosen,
          curr,
          totes: totes,
          totespredis: {},
          subtvalue: 0,
          taxes: tax,
        });

        chosen[stab].tax = tax;
        chosen[stab].total = Number(totes).toFixed(2);

        dispatch({ type: 'setChosen', chosen });
      };
    }
    return () => {};
  };

  const changeTotal = () => {
    const curr = prop.curr || currency || configText('currency');
    const chosen = _.merge({}, props.chosen);
    if (chosen[stab]) {
      return (e) => {
        const v =
          prop.priceSeperator === '.'
            ? e?.target?.value.replace(/[^0-9,]/g, '')
            : e?.target?.value.replace(/[^0-9.]/g, '');
        const only = helpers.onlyNumSeperator({
          prop,
          x: v,
          curr,
          changeTotal: true,
        });
        let subtvalue = only;

        [, subtvalue] = calculateDiscount({ chosen, curr, totes: {}, totespredis: {}, subtvalue });
        [, subtvalue] = calculateTax({ chosen, curr, totes: {}, totespredis: {}, subtvalue });

        chosen[stab].total = prop.priceSeperator === '.' ? only.toString().replace(',', '.') : only;
        chosen[stab].subtotalValue =
          prop.priceSeperator === '.' ? subtvalue.toString().replace(',', '.') : subtvalue;

        dispatch({ type: 'setChosen', chosen });
      };
    }
    return () => {};
  };

  // change discount unit for component
  const changeDiscountUnit = () => {
    const chosen = _.merge({}, props.chosen);

    if (chosen[stab]) {
      return () => {
        if (!chosen[stab].discount.unit) {
          chosen[stab].discount.unit = '%';
        } else {
          delete chosen[stab].discount.unit;
        }
        // make discount value 0
        chosen[stab].discount.val = 0;

        // make discount value 0
        chosen[stab].discount.val = 0;

        const dis = chosen[stab].discount;
        let totes = chosen[stab].subtotalValue ? Number(chosen[stab].subtotalValue) : 0;

        [totes] = calculateDiscount({
          chosen,
          curr,
          totes: totes,
          totespredis: {},
          subtvalue: 0,
          discount: dis,
        });

        [totes] = calculateTax({
          chosen,
          curr,
          totes: totes,
          totespredis: {},
          subtvalue: 0,
        });
        chosen[stab].total = Number(totes).toFixed(2);

        dispatch({ type: 'setChosen', chosen });
      };
    }
    return () => {};
  };

  const changeTaxUnit = () => {
    const chosen = _.merge({}, props.chosen);

    if (chosen[stab]) {
      return () => {
        if (!chosen[stab].tax.unit) {
          chosen[stab].tax.unit = '%';
          if (chosen[stab].tax.val > 99) {
            chosen[stab].tax.val = 5;
          }
        } else {
          delete chosen[stab].tax.unit;
        }

        const { tax } = chosen[stab];

        let totes = chosen[stab].subtotalValue ? Number(chosen[stab].subtotalValue) : 0;

        [totes] = calculateDiscount({
          chosen,
          curr,
          totes: totes,
          totespredis: {},
          subtvalue: 0,
        });

        [totes] = calculateTax({
          chosen,
          curr,
          totes: totes,
          totespredis: {},
          subtvalue: 0,
          taxes: tax,
        });
        chosen[stab].total = Number(totes).toFixed(2);

        dispatch({ type: 'setChosen', chosen });
      };
    }
    return () => {};
  };

  const changeTabName = (e) => {
    const chosen = _.merge({}, props.chosen);
    chosen[stab] = chosen[stab] || {};
    chosen[stab].name = typeof e === 'string' ? e : e?.target?.value || '';
    dispatch({ type: 'setChosen', chosen });
  };

  const saveTabName = () => {
    const chosen = _.merge({}, props.chosen);
    chosen[stab] = chosen[stab] || {};
    chosen[stab].name = sanitizeString(chosen[stab].name.trim());
    dispatch({ type: 'setChosen', chosen });
  };

  const handleValueOptionsDropdown = (value) => {
    const chosen = _.merge({}, props.chosen);

    const options = chosen[stab];
    options.type = value;
    chosen[stab] = options;

    dispatch({ type: 'setChosen', chosen });
  };

  const delOption = (t) => {
    return () => {
      const chosen = _.merge({}, props.chosen);
      delete chosen[t];
      const [tempStab] = Object.keys(chosen);
      dispatch({ type: 'setChosen', chosen });
      dispatch({ type: 'setStab', stab: tempStab });
    };
  };

  const addOption = () => {
    const chosen = _.merge({}, props.chosen);
    let len = Object.keys(chosen).length || 1;

    if (len >= 6) {
      return;
    }

    while (chosen[`OPTION ${len}`]) {
      len++;
    }
    chosen[`OPTION ${len}`] = { list: [] };

    dispatch({ type: 'setChosen', chosen });
  };

  const onEdit = (targetKey, action) => {
    if (action === 'add') addOption();
    else if (action === 'remove') {
      dispatch({
        type: 'setOptionsDeleteModal',
        optionsDeleteModal: targetKey,
      });
    }
  };

  const getpkgname = (t, chosendata) => {
    if (chosendata?.name) {
      return chosendata.name;
    }

    if (chosendata?.name === '') {
      return '';
    }

    let optionNumber = t || 'Option 1';

    if (prop?.language?.toLowerCase() === 'hebrew' && optionNumber.search('OPTION') > -1) {
      // if hebrew, replace option 1 with אופציה 1
      return optionNumber.replace('OPTION', 'אופציה');
    }

    return configText(
      optionNumber.charAt(0).toUpperCase() + optionNumber.substring(1).toLowerCase()
    );
  };

  const newchosen = {};
  const { chosen } = props;

  const {
    selectable,
    discount: dis,
    tax,
    roundTotal,
    quantity,
    type: valuePayemntType,
  } = (chosen && chosen[stab]) || {};
  const curr = prop.curr || currency || configText('currency');

  if (
    price &&
    chosen &&
    chosen[Object.keys(chosen)[0]] &&
    chosen[Object.keys(chosen)[0]].list.length
  ) {
    chosen[Object.keys(chosen)[0]].list.forEach((i) => {
      newchosen[i.type] = newchosen[i.type] ? newchosen[i.type] : {};
      newchosen[i.type].name = i.type ? i.type : 'default';
      newchosen[i.type].list =
        newchosen[i.type].list && newchosen[i.type].list.length ? newchosen[i.type].list : [];
      newchosen[i.type].list.push(i);
      newchosen[i.type].quantity = chosen[stab].quantity;
      newchosen[i.type].selectable = chosen[stab].selectable;
    });
  }

  let totes = calculateSubTotal();

  const totespredis = totes;

  let subtvalue = totes;

  let multipleTotal = {};

  const fdisvalraw = dis && helpers.parseFloatCurr({ prop, x: dis.val, curr });
  const ftaxvalraw = tax && helpers.parseFloatCurr({ prop, x: tax.val, curr });

  const fdisval = dis && Math.abs(fdisvalraw) * (dis.type ? dis.type : fdisvalraw < 0 ? -1 : 1);
  const ftaxval = tax && Math.abs(ftaxvalraw) * (tax.type ? tax.type : ftaxvalraw < 0 ? -1 : 1);

  // if (price) {
  [totes, subtvalue] = calculateDiscount({
    fdisval,
    chosen,
    curr,
    totes,
    totespredis,
    subtvalue,
  });
  [totes, subtvalue] = calculateTax({ ftaxval, chosen, curr, totes, totespredis, subtvalue });
  // }

  if (
    Object.keys(newchosen).length === 1 &&
    newchosen['One-Time'] &&
    Object.keys(newchosen['One-Time']).length
  ) {
    multipleTotal = {};
  } else if (Object.keys(newchosen).length) {
    _.map(newchosen, (v, k) => {
      let newtotes = calculateSubTotal(v);
      let subTotal = newtotes;

      [newtotes, subTotal] = calculateDiscount({
        fdisval,
        chosen,
        curr,
        totes: newtotes,
        totespredis: newtotes,
        subtvalue: subTotal,
      });
      [newtotes, subTotal] = calculateTax({
        ftaxval,
        chosen,
        curr,
        totes: newtotes,
        totespredis: newtotes,
        subtvalue: subTotal,
      });

      const totalValue = newchosen[k]?.name !== 'default' ? `${newchosen[k].name}` : '';
      const totalText = utils.transformPopoverText(
        helpers.upr(configText('total text')),
        totalValue,
        configText,
        prop?.language?.toLowerCase()
      );

      multipleTotal[newchosen[k].name] = {
        value: totalText,
        type: totalValue,
        subTotal,
        newtotes,
      };
    });
  }

  const onSort = async (sortedList) => {
    const unOrderedItem = chosen;
    const orderedItem = {};

    sortedList.forEach((sortList) => {
      orderedItem[sortList] = unOrderedItem[sortList];
    });

    dispatch({ type: 'setChosen', chosen: orderedItem, orderSave: true });
  };

  // edit column name only for structured
  const editColumnName = (key, value, max = 20) => {
    const columnName = chosen[stab]?.columnName || {};
    // remove new line character from value
    value = value.replace(/(\r\n|\n|\r)/gm, '');
    value = !!sanitizeString(value).trim() ? sanitizeString(value) : '';
    columnName[key] = value.substring(0, max);

    chosen[stab].columnName = columnName;

    dispatch({ type: 'setChosen', chosen });
  };

  const renderPricingSortlist = (
    <DraggableTabs
      type="editable-card"
      onSort={onSort}
      onEdit={onEdit}
      activeKey={stab}
      onChange={(key) => dispatch({ type: 'setStab', stab: key })}>
      {_.map(chosen, (chosendata, t) => (
        <TabPane tab={getpkgname(t, chosen[t])} key={t}>
          <Sortlist
            t={t}
            configText={configText}
            listItem={chosendata?.list}
            quantity={quantity}
            selectable={selectable}
            valuePayemntType={valuePayemntType}
            changeTabName={changeTabName}
            saveTabName={saveTabName}
            handleValueOptionsDropdown={handleValueOptionsDropdown}
            onlistChange={(list) => listChanged(stab, list)}
            getpkgname={getpkgname}
            delOption={delOption}
            calculateDiscount={calculateDiscount}
            calculateTax={calculateTax}
            calcDiscPrice={calcDiscPrice}
            setOpenDrawer={setOpenDrawer}
            editColumnName={editColumnName}
            {...props}
          />
        </TabPane>
      ))}
    </DraggableTabs>
  );

  const renderSortlist = _.map(chosen, (chosendata, t) => (
    <Sortlist
      t={t}
      key={t}
      configText={configText}
      listItem={chosendata?.list}
      quantity={quantity}
      selectable={selectable}
      valuePayemntType={valuePayemntType}
      changeTabName={changeTabName}
      saveTabName={saveTabName}
      handleValueOptionsDropdown={handleValueOptionsDropdown}
      onlistChange={(list) => listChanged(stab, list)}
      getpkgname={getpkgname}
      delOption={delOption}
      calculateDiscount={calculateDiscount}
      calculateTax={calculateTax}
      calcDiscPrice={calcDiscPrice}
      saveUser={saveUser}
      editColumnName={editColumnName}
      {...props}
    />
  ));

  const showSubTotal = chosen ? !!chosen[stab]?.subtotal : 0;
  let showTotal = true;
  if (chosen && typeof chosen[stab]?.showTotal === 'undefined') {
    showTotal = true;
  } else {
    showTotal = chosen ? !!chosen[stab]?.showTotal : false;
  }

  const structured = !!chosen[stab]?.structured || false;
  const discountAvailable = !!dis;

  const showPane = () => {
    setOpenDrawer('all-options');
    if (visible || discountVisible || taxVisible) {
      setShowDrawerTotals(true);
      setDiscountVisible(false);
      setTaxVisible(false);
      setVisible(false);
    }
  };

  const onClosePane = () => {
    setOpenDrawer(false);
    if (showDrawerTotals) {
      setShowDrawerTotals(false);
      setVisible(true);
    }
  };

  const decimalPrecision = prop?.decimalPrecision || 2;
  const currencyOptions = {
    curr: curr || configText('currency'),
    limitDec: decimalPrecision,
    parsingDecimal: true,
  };

  let totalText = '';

  const clickHideButton = () => {
    setVisible(false);
    setDiscountVisible(false);
    setTaxVisible(false);
    setShowDrawerTotals(false);
  };

  useEffect(() => {
    if (discountBtn) setDiscountVisible(true);
    if (taxBtn) setTaxVisible(true);
  }, [discountBtn, taxBtn]);

  const subTotalElement = () => {
    return Object.keys(multipleTotal).length === 1 ||
      ((dis || tax) && !Object.keys(multipleTotal).length) ? (
      <Row className="totals-content-container">
        <Col className="totals-info" span={14}>
          <Input
            className="sub-total-text"
            onChange={changeSubtotalText()}
            value={chosen[stab]?.subtotalText || configText('subtotal text')}
          />
          {!price && <div className="totals-currency">{curr}</div>}
        </Col>
        <Col className="totals-content" span={10}>
          {price ? (
            <div className="totals-content-currency">
              {helpers.concatcurr({
                prop,
                x:
                  prop.priceSeperator === '.'
                    ? subtvalue?.toString().replace('.', ',') || 0
                    : subtvalue || 0,
                ...currencyOptions,
              })}
            </div>
          ) : (
            <Input
              className="sub-total-text"
              readOnly={price ? true : false}
              onChange={changeSubtotalValue()}
              value={
                prop.priceSeperator === '.'
                  ? chosen[stab]?.subtotalValue === chosen[stab]?.total &&
                    (chosen[stab]?.tax || chosen[stab]?.discount)
                    ? subtvalue?.toString().replace('.', ',') || 0
                    : chosen[stab]?.subtotalValue?.toString().replace('.', ',') || 0
                  : chosen[stab]?.subtotalValue === chosen[stab]?.total &&
                    (chosen[stab]?.tax || chosen[stab]?.discount)
                  ? subtvalue || 0
                  : chosen[stab]?.subtotalValue || 0
              }
            />
          )}
        </Col>
      </Row>
    ) : Object.keys(multipleTotal).length ? (
      Object.keys(multipleTotal).map((d, multipleTotalIndex) => (
        <Row className="totals-content-container" key={multipleTotalIndex}>
          <Col className="totals-info" span={14}>
            <Input
              className="sub-total-text"
              onChange={changeSubtotalText()}
              value={
                chosen[stab]?.subtotalText ||
                (configText('subtotal text') && multipleTotal[d]?.type)
                  ? utils.transformPopoverText(
                      configText('subtotal text'),
                      multipleTotal[d]?.type,
                      configText,
                      prop?.language?.toLowerCase()
                    )
                  : configText('subtotal text')
              }
            />
          </Col>
          <Col className="totals-content" span={10}>
            <div>
              {helpers.concatcurr({ prop, x: multipleTotal[d].subTotal, ...currencyOptions })}
            </div>
          </Col>
        </Row>
      ))
    ) : null;
  };

  const discountElement = () => (
    <Row className="totals-content-container">
      <Col className="totals-info" span={14}>
        <Input
          className="dis-text"
          onChange={changeDiscountText()}
          value={chosen[stab].discount.text || helpers.upr(configText('discount text'))}
        />
      </Col>
      <Col className="totals-content" span={10}>
        <Input
          containerclass="tablist-input"
          placeholder="value"
          value={dis.val}
          onChange={changeDiscountVal()}
        />
        <span className="totals-content-unit" onClick={changeDiscountUnit()}>
          {dis.unit || curr || configText('currency')}
        </span>
      </Col>
    </Row>
  );

  const subtotalBeforeTaxElement = () => {
    let tempSubtotal;
    let newTotal;
    let subTotalBeforeTax;

    if (
      Object.keys(multipleTotal).length === 1 ||
      ((dis || tax) && !Object.keys(multipleTotal).length)
    ) {
      tempSubtotal = price
        ? subtvalue
        : chosen[stab]?.subtotalValue === chosen[stab]?.total &&
          (chosen[stab]?.tax || chosen[stab]?.discount)
        ? subtvalue
        : chosen[stab]?.subtotalValue;

      [newTotal] = calculateDiscount({
        chosen,
        curr,
        totes: tempSubtotal,
        totespredis: tempSubtotal,
        subtvalue: tempSubtotal,
        discount: chosen[stab]?.discount,
      });

      subTotalBeforeTax =
        prop.priceSeperator === '.' ? newTotal?.toString().replace('.', ',') || 0 : newTotal || 0;
    }

    // for multiple price (one time, monthly etc)
    else {
      Object.keys(multipleTotal).forEach((key) => {
        const tempSubtotal = multipleTotal[key].subTotal;
        [newTotal] = calculateDiscount({
          chosen,
          curr,
          totes: tempSubtotal,
          totespredis: tempSubtotal,
          subtvalue: tempSubtotal,
          discount: chosen[stab]?.discount,
        });

        multipleTotal[key].subTotalBeforeTax = Math.max(newTotal, 0);
      });
    }

    return Object.keys(multipleTotal).length === 1 ||
      ((dis || tax) && !Object.keys(multipleTotal).length) ? (
      <Row className="totals-content-container">
        <Col className="totals-info" span={14}>
          <Input
            className="sub-total-text total-before-text"
            onChange={changesubtotalBeforeTaxText()}
            value={chosen[stab]?.subtotalBeforeTaxText || configText('total before tax')}
          />
          {!price && <div className="totals-currency">{curr}</div>}
        </Col>
        <Col className="totals-content" span={10}>
          {price ? (
            <div className="totals-content-currency">
              {helpers.concatcurr({
                prop,
                x: subTotalBeforeTax,
                ...currencyOptions,
              })}
            </div>
          ) : (
            <Input
              className="sub-total-text total-before-text"
              readOnly={true}
              value={subTotalBeforeTax}
            />
          )}
        </Col>
      </Row>
    ) : Object.keys(multipleTotal).length ? (
      Object.keys(multipleTotal).map((d, multipleTotalIndex) => (
        <Row className="totals-content-container" key={multipleTotalIndex}>
          <Col className="totals-info" span={14}>
            <Input
              className="sub-total-text total-before-tax"
              onChange={changesubtotalBeforeTaxText(multipleTotal[d]?.type)}
              value={
                typeof chosen[stab]?.[`subtotalBeforeTaxText${multipleTotal[d]?.type}`] === 'string'
                  ? chosen[stab][`subtotalBeforeTaxText${multipleTotal[d]?.type}`]
                  : utils.transformPopoverText(
                      configText('total before tax'),
                      multipleTotal[d]?.type || '',
                      configText,
                      prop?.language?.toLowerCase()
                    )
              }
            />
          </Col>
          <Col className="totals-content" span={10}>
            <div>
              {helpers.concatcurr({
                prop,
                x: multipleTotal[d].subTotalBeforeTax,
                ...currencyOptions,
              })}
            </div>
          </Col>
        </Row>
      ))
    ) : null;
  };

  const taxElement = () => (
    <Row className="totals-content-container">
      <Col className="totals-info" span={14}>
        <Input
          className="tax-text"
          onChange={changeTaxText()}
          value={chosen[stab].tax.text || helpers.upr(configText('tax text'))}
        />
      </Col>
      <Col className="totals-content" span={10}>
        <Input
          containerclass="tablist-input"
          placeholder="value"
          value={tax.val}
          onChange={changeTaxVal()}
        />
        <span className="totals-content-unit" onClick={changeTaxUnit()}>
          {tax.unit || curr || configText('currency')}
        </span>
      </Col>
    </Row>
  );

  const totalElement = ({
    showTotal,
    totalValue,
    type,
    roundedTotal,
    d,
    totalIndex,
    totalValueWithCurr,
  }) => {
    return (
      <Row key={totalIndex} className="totals-content-container">
        <Col className="totals-info" span={14}>
          {type === 'multiple' ? (
            <Input className="total-text" value={multipleTotal[d].value} readOnly />
          ) : (
            <Input
              className="total-text"
              onChange={changeTotalText()}
              onFocus={(e) => e.target.select()}
              value={
                chosen && typeof chosen[stab]?.totalText === 'string'
                  ? chosen[stab]?.totalText
                  : helpers.upr(configText('total text'))
              }
            />
          )}
        </Col>
        <Col className="totals-content" span={10}>
          {type === 'price' && <div className="totals-content-currency">{totalValueWithCurr}</div>}

          {type === 'tabs' && (
            <>
              <Col className="totals-currency">
                <div className="totals-currency">{curr}</div>
              </Col>
              <input
                className="totals-content-currency"
                value={
                  prop.priceSeperator === '.'
                    ? totalValue?.toString().replace('.', ',') || 0
                    : totalValue || 0
                }
                placeholder={`${curr} Set your price`}
                onChange={changeTotal()}
              />
            </>
          )}

          {type === 'multiple' && (
            <div className="totals-content-currency">
              {helpers.concatcurr({
                prop,
                x: multipleTotal[d].newtotes,
                roundedTotal,
                minimum: 0,
                ...currencyOptions,
              })}
            </div>
          )}
        </Col>
      </Row>
    );
  };

  const total = () => {
    let totalValue = '';
    let totalValueWithCurr = '';
    let showTotal = true;
    let type = 'price';
    const roundedTotal = roundTotal;

    if (!price) {
      totalValueWithCurr = helpers.concatcurr({
        prop,
        x:
          prop.priceSeperator === '.'
            ? chosen
              ? chosen[stab]?.total?.toString().replace('.', ',')
              : 0
            : chosen
            ? chosen[stab]?.total
            : 0,
        ...currencyOptions,
        roundedTotal,
      });
      totalValue = chosen ? chosen[stab]?.total : 0;
    } else {
      if (totes < 0) {
        totes = 0;
      }
      totalValueWithCurr = helpers.concatcurr({
        prop,
        x: prop.priceSeperator === '.' ? totes?.toString().replace('.', ',') || 0 : totes || 0,
        curr: curr || configText('currency'),
        limitDec: decimalPrecision,
        roundedTotal,
        parsingDecimal: true,
      });
      totalValue = totes;
    }

    if (chosen && chosen[stab] && typeof chosen[stab].showTotal === 'undefined') {
      showTotal = true;
    } else {
      showTotal = chosen && chosen[stab] ? !!chosen[stab].showTotal : true;
    }

    if (Object.keys(multipleTotal).length) {
      if (Object.keys(multipleTotal)[0] === 'default' && Object.keys(multipleTotal).length === 1) {
        type = price ? 'price' : 'tabs';
      } else {
        type = 'multiple';
      }
    } else {
      type = price ? 'price' : 'tabs';
    }

    if (type === 'multiple') {
      totalText = (
        <div className="totals-container-tag-info">
          <p>Multiple {helpers.upr(configText('total text'))}</p>
        </div>
      );
    } else if (type === 'price') {
      totalText = (
        <div className="totals-container-tag-info">
          <p>
            {(chosen && chosen.length && chosen[stab].totalText) ||
              helpers.upr(configText('total text'))}
          </p>
          <p className="totals-container-tag-price">{totalValueWithCurr || 0}</p>
        </div>
      );
    } else if (type === 'tabs') {
      totalText = (
        <div className="totals-container-tag-info">
          <p>{helpers.upr(configText('total text'))}</p>
          <p className="totals-container-tag-price">{totalValueWithCurr || 0}</p>
        </div>
      );
    }

    return showTotal
      ? type === 'multiple'
        ? Object.keys(multipleTotal).map((d, totalIndex) =>
            totalElement({
              showTotal,
              totalValue,
              type,
              roundedTotal,
              d,
              totalIndex,
              totalValueWithCurr,
            })
          )
        : totalElement({ showTotal, totalValue, type, roundedTotal, totalValueWithCurr })
      : null;
  };

  const popOverContent = (
    <>
      {total() ? (
        <div className="totals-container">
          <div className="totals-container-close">
            <button
              className="totals-container-close-button"
              type="button"
              onClick={clickHideButton}>
              Hide
            </button>
          </div>
          <Col className="totals-content">
            {showSubTotal && subTotalElement()}
            {dis && discountElement()}
            {dis && tax && !!chosen[stab]?.subtotalBeforeTax && subtotalBeforeTaxElement()}
            {tax && taxElement()}
            {total()}
          </Col>
        </div>
      ) : null}
    </>
  );

  const resetColumnName = () => {
    const columnName = chosen[stab]?.columnName || {};
    columnName.deliverable = configText('Deliverable');
    columnName.item = configText('Quantity');
    columnName.price = configText('Line Total');
    columnName.rowprice = configText('Price');

    chosen[stab].columnName = columnName;

    dispatch({ type: 'setChosen', chosen });
  };

  return (
    <>
      {openDrawer === 'all-options' && (
        <AllOptionsDrawer
          onClosePane={onClosePane}
          toggleDiscount={toggleDiscount}
          dis={dis}
          toggleTax={toggleTax}
          clickTaxBtn={clickTaxBtn}
          taxBtn={taxBtn}
          tax={tax}
          toggleQuantity={toggleQuantity}
          quantity={quantity}
          toggleSelectable={toggleSelectable}
          selectable={selectable}
          toggleRoundedTotal={toggleRoundedTotal}
          roundTotal={roundTotal}
          toggleSubtotal={toggleSubtotal}
          showSubTotal={showSubTotal}
          isWizard={isWizard}
          popOverContent={popOverContent}
          setVisible={setVisible}
          discountAvailable={discountAvailable}
          toggleSubtotalBeforeTax={toggleSubtotalBeforeTax}
          chosen={chosen}
          stab={stab}
          toggleTotal={toggleTotal}
          showTotal={showTotal}
          toggleStructured={toggleStructured}
          structured={structured}
          showDrawerTotals={showDrawerTotals}
        />
      )}

      {openDrawer === 'edit-title' && (
        <TitleEditModal
          onClosePane={onClosePane}
          editColumnName={editColumnName}
          resetColumnName={resetColumnName}
          chosen={chosen}
          stab={stab}
          configText={configText}
        />
      )}

      <Col span={18} className="tablist-content">
        <div className={price ? 'ant-tabs-nav-disable' : 'ant-tabs-nav-enable'}>
          {optionsDeleteModal && (
            <ConfirmModal
              title="Are You Sure?"
              body="This will delete the option."
              visible={optionsDeleteModal}
              cancelText="CANCEL"
              confirmText="YES, DELETE"
              onCancel={() =>
                dispatch({
                  type: 'setOptionsDeleteModal',
                  optionsDeleteModal: false,
                })
              }
              onConfirm={() => {
                delOption(optionsDeleteModal)();
                dispatch({
                  type: 'setOptionsDeleteModal',
                  optionsDeleteModal: false,
                });
              }}
            />
          )}
          {price || tabs ? (
            <>
              <TotalOptions
                prop={prop}
                curr={curr}
                config={config}
                configText={configText}
                quantity={quantity}
                discount={dis}
                tax={tax}
                save={save}
                selectable={selectable}
                toggleDiscount={toggleDiscount}
                toggleTax={toggleTax}
                toggleQuantity={toggleQuantity}
                toggleSelectable={toggleSelectable}
                stab={stab}
                dispatch={dispatch}
                defaultStab={chosen ? Object.keys(chosen)[0] : undefined}
                isWizard={isWizard}
                discountBtn={discountBtn}
                setTaxBtn={setTaxBtn}
                taxBtn={taxBtn}
                clickDiscountBtn={clickDiscountBtn}
                clickTaxBtn={clickTaxBtn}
                handleState={handleState}
                openDrawer={openDrawer}
                showPane={showPane}
                setDiscountBtn={setDiscountBtn}
                disctBtn={discountBtn}
              />
              {renderPricingSortlist}
              <Totals
                dis={dis}
                tax={tax}
                curr={curr}
                totes={totes}
                showTotal={showTotal}
                subtvalue={subtvalue}
                showSubTotal={showSubTotal}
                showSubTotalBeforeTax={!!chosen[stab]?.subtotalBeforeTax}
                multipleTotal={multipleTotal}
                changeTotal={changeTotal}
                changeDiscountUnit={changeDiscountUnit}
                changeTaxUnit={changeTaxUnit}
                changeTotalText={changeTotalText}
                changeSubtotalText={changeSubtotalText}
                changesubtotalBeforeTaxText={changesubtotalBeforeTaxText}
                changeSubtotalValue={changeSubtotalValue}
                changeDiscountText={changeDiscountText}
                changeTaxText={changeTaxText}
                changeDiscountVal={changeDiscountVal}
                changeTaxVal={changeTaxVal}
                roundTotal={roundTotal}
                discountBtn={discountBtn}
                taxBtn={taxBtn}
                calculateDiscount={calculateDiscount}
                setVisible={setVisible}
                visible={visible}
                taxVisible={taxVisible}
                setTaxVisible={setTaxVisible}
                totalText={totalText}
                subTotalElement={subTotalElement}
                discountElement={discountElement}
                subtotalBeforeTaxElement={subtotalBeforeTaxElement}
                taxElement={taxElement}
                totalElement={totalElement}
                total={total}
                setDiscountVisible={setDiscountVisible}
                discountVisible={discountVisible}
                {...props}
              />
            </>
          ) : (
            renderSortlist
          )}
        </div>
      </Col>
    </>
  );
};

ChoosenTotals.defaultProps = {
  totalValue: 0,
  chosen: '',
  config: '',
  chosendata: '',
  price: false,
  tabs: false,
  isWizard: false,
};

ChoosenTotals.propTypes = {
  chosendata: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  chosen: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  listChanged: PropTypes.func.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  price: PropTypes.bool,
  prop: PropTypes.instanceOf(Object).isRequired,
  currency: PropTypes.string.isRequired,
  config: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  configText: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  editstrategy: PropTypes.string.isRequired,
  totalValue: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
  tabs: PropTypes.bool,
  isWizard: PropTypes.bool,
  handleState: PropTypes.func.isRequired,
};

export default ChoosenTotals;
