/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.5 18H15.5C15.7833 18 16.0208 17.9042 16.2125 17.7125C16.4042 17.5208 16.5 17.2833 16.5 17C16.5 16.7167 16.4042 16.4792 16.2125 16.2875C16.0208 16.0958 15.7833 16 15.5 16H9.5C9.21667 16 8.97917 16.0958 8.7875 16.2875C8.59583 16.4792 8.5 16.7167 8.5 17C8.5 17.2833 8.59583 17.5208 8.7875 17.7125C8.97917 17.9042 9.21667 18 9.5 18ZM9.5 14H15.5C15.7833 14 16.0208 13.9042 16.2125 13.7125C16.4042 13.5208 16.5 13.2833 16.5 13C16.5 12.7167 16.4042 12.4792 16.2125 12.2875C16.0208 12.0958 15.7833 12 15.5 12H9.5C9.21667 12 8.97917 12.0958 8.7875 12.2875C8.59583 12.4792 8.5 12.7167 8.5 13C8.5 13.2833 8.59583 13.5208 8.7875 13.7125C8.97917 13.9042 9.21667 14 9.5 14ZM6.5 22C5.95 22 5.47917 21.8042 5.0875 21.4125C4.69583 21.0208 4.5 20.55 4.5 20V4C4.5 3.45 4.69583 2.97917 5.0875 2.5875C5.47917 2.19583 5.95 2 6.5 2H13.675C13.9417 2 14.1958 2.05 14.4375 2.15C14.6792 2.25 14.8917 2.39167 15.075 2.575L19.925 7.425C20.1083 7.60833 20.25 7.82083 20.35 8.0625C20.45 8.30417 20.5 8.55833 20.5 8.825V20C20.5 20.55 20.3042 21.0208 19.9125 21.4125C19.5208 21.8042 19.05 22 18.5 22H6.5ZM13.5 8C13.5 8.28333 13.5958 8.52083 13.7875 8.7125C13.9792 8.90417 14.2167 9 14.5 9H18.5L13.5 4V8Z"
      fill="#5D5AB5"
      className="filled"
    />
    <path
      d="M9 17.75H15C15.2128 17.75 15.391 17.6782 15.5345 17.5345C15.6782 17.391 15.75 17.2128 15.75 17C15.75 16.7872 15.6782 16.609 15.5345 16.4655C15.391 16.3218 15.2128 16.25 15 16.25H9C8.78717 16.25 8.609 16.3218 8.4655 16.4655C8.32183 16.609 8.25 16.7872 8.25 17C8.25 17.2128 8.32183 17.391 8.4655 17.5345C8.609 17.6782 8.78717 17.75 9 17.75ZM9 13.75H15C15.2128 13.75 15.391 13.6782 15.5345 13.5345C15.6782 13.391 15.75 13.2128 15.75 13C15.75 12.7872 15.6782 12.609 15.5345 12.4655C15.391 12.3218 15.2128 12.25 15 12.25H9C8.78717 12.25 8.609 12.3218 8.4655 12.4655C8.32183 12.609 8.25 12.7872 8.25 13C8.25 13.2128 8.32183 13.391 8.4655 13.5345C8.609 13.6782 8.78717 13.75 9 13.75ZM6.30775 21.5C5.80258 21.5 5.375 21.325 5.025 20.975C4.675 20.625 4.5 20.1974 4.5 19.6923V4.30775C4.5 3.80258 4.675 3.375 5.025 3.025C5.375 2.675 5.80258 2.5 6.30775 2.5H13.502C13.743 2.5 13.9748 2.54683 14.1973 2.6405C14.4196 2.734 14.6128 2.86283 14.777 3.027L18.973 7.223C19.1372 7.38717 19.266 7.58042 19.3595 7.80275C19.4532 8.02525 19.5 8.257 19.5 8.498V19.6923C19.5 20.1974 19.325 20.625 18.975 20.975C18.625 21.325 18.1974 21.5 17.6923 21.5H6.30775ZM13.5 7.596V4H6.30775C6.23075 4 6.16025 4.03208 6.09625 4.09625C6.03208 4.16025 6 4.23075 6 4.30775V19.6923C6 19.7693 6.03208 19.8398 6.09625 19.9038C6.16025 19.9679 6.23075 20 6.30775 20H17.6923C17.7692 20 17.8398 19.9679 17.9038 19.9038C17.9679 19.8398 18 19.7693 18 19.6923V8.5H14.404C14.1462 8.5 13.9311 8.41375 13.7587 8.24125C13.5862 8.06892 13.5 7.85383 13.5 7.596Z"
      fill="#05034D"
      className="outline"
    />
  </svg>
);

const ProposalIcon = (props) => <Icon component={svg} {...props} />;

export default ProposalIcon;
